import React from 'react';
import Layout from '../Layout';
import ServiceBanner from '../components/Services/ServiceBanner';
import ServiceScope from '../components/Services/ServiceScope';
import ServiceSetup from '../components/Services/ServiceSetup';
import ServiceAdvantages from '../components/Services/ServiceAdvantages';
import ServiceCost from '../components/Services/ServiceCost';
import PromoBlock from '../components/PromoBlock';
import PortfolioMain from '../components/PortfolioMain';
import BlogMainPage from '../components/BlogMainPage';
import { TYPES_SERVICE } from '../const';

const ServicesPage = (props) => {
  const { object, global, posts } = props;

  return (
    <Layout {...props}>
      <ServiceBanner policy={global.policy_page} page_info={object.paige_info} type={TYPES_SERVICE.context_ad} title={object.consultation_title} />
      <ServiceScope context_ad_info={object.context_ad_info} capabilities={object.capabilities} title={object.capabilities_title} />
      <ServiceSetup ad_blocks={object.ad_blocks} title={object.ad_blocks_title} />
      <ServiceAdvantages advantages={object.advantages} title={object.advantages_title} />
      <ServiceCost type={TYPES_SERVICE.context_ad} price={object.price} service_info={object.service_info} title={object.service_info_title} />
      <PromoBlock type={TYPES_SERVICE.context_ad} data={object.banner} policy={global.policy_page} />
      <PortfolioMain link={global.settings.caselist_url} data={object.portfolio} />
      { posts.length !== 0
                && <BlogMainPage title="Популярные статьи" link={global.settings.blog_url} data={posts} />}
    </Layout>
  );
};

export default ServicesPage;
