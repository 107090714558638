import React from 'react';
import dayjs from 'dayjs';
import style from './index.module.scss';
import Tag from '../../CommonViews/Tag';
import 'dayjs/locale/ru';

function ArticleHeader({ data }) {
  const {
    author, description, title, image, tags, pub_date,
  } = data;
  return (
    <div className={style.article_header__container}>
      <div className={style.article_header}>
        <div className={style.article_header__bg}>
          <img
            src={image}
            alt={title}
          />
        </div>
        <div className={style.article_header_content}>
          <h2 className={style.article_header_content__title}>
            {title}
          </h2>
          <p className={style.article_header_content__subtitle} dangerouslySetInnerHTML={{ __html: description }} />
          <div className={style.article_header_content_info}>
            <div className={style.article_header_content_info_left}>
              <div className={style.article_header_content_info_author}>
                {author.image
                  ? (
                    <div className={style.article_header_content_info_author__avatar}>
                      <img src={author.image} alt="Аватар автора" />
                    </div>
                  )
                  : null}
                <div className={style.article_header_content_info_author_info}>
                  <p className={style.article_header_content_info_author_info__name}>
                    {author.first_name}
                    {' '}
                    {author.last_name}
                  </p>
                  <p className={style.article_header_content_info_author_info__post}>{author.position}</p>
                </div>
              </div>
              <div className={style.article_header_content_info__date}>
                {dayjs(pub_date).locale('ru').format('D MMMM YYYY')}
              </div>
            </div>
            <ul className={style.article_header_content_info__taglist}>
              {tags.map(({ id, name }) => (
                <Tag
                  key={id}
                  id={id}
                  name={name}
                  isFilterTag
                  isArticleTag
                  projectVariant="tag__card"
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ArticleHeader;
