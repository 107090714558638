import React from 'react';
import style from './index.module.scss';
import trian from '../../../images/animateElements/trianurgul.svg';
import Form from '../../CommonViews/Form';

const PageForm = ({ typeForm = '', global }) => {
  const { policy_page } = global;
  return (
    <section id="feedback" className={style.pageform}>
      <div className={style.pageform__row}>
        <div className={style.pageform__left}>
          <div className={style.pageform_name}>
            Начните работу
            {' '}
            <br />
            прямо сейчас
          </div>
          <div className={style.pageform__trians}>
            <img src={trian} alt="" className={style.pageform__trian1} />
            <img src={trian} alt="" className={style.pageform__trian2} />
            <img src={trian} alt="" className={style.pageform__trian3} />
            <img src={trian} alt="" className={style.pageform__trian4} />
            <img src={trian} alt="" className={style.pageform__trian5} />
            <img src={trian} alt="" className={style.pageform__trian6} />
            <img src={trian} alt="" className={style.pageform__trian7} />
            <img src={trian} alt="" className={style.pageform__trian8} />
            <img src={trian} alt="" className={style.pageform__trian9} />
          </div>
        </div>
        <div className={style.pageform__right}>
          <Form policy={policy_page} type={typeForm} />
        </div>
      </div>
    </section>
  );
};

export default PageForm;
