import React from 'react';
import square from '../../../../images/animateElements/square.svg';
import style from './index.module.scss';

const Squares = () => {
  return (
    <>
      <img src={square} alt="" className={style.square1} />
      <img src={square} alt="" className={style.square2} />
      <img src={square} alt="" className={style.square3} />
      <img src={square} alt="" className={style.square4} />
      <img src={square} alt="" className={style.square5} />
      <img src={square} alt="" className={style.square6} />
      <img src={square} alt="" className={style.square7} />
      <img src={square} alt="" className={style.square8} />
      <img src={square} alt="" className={style.square9} />
    </>
  );
};

export default Squares;
