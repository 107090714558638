import React from 'react';
import Layout from '../Layout';
import ArticleHeader from '../components/Article/ArticleHeader';
import ArticleBody from '../components/Article/ArticleBody';
import SubscribeBlock from '../components/Blog/SubsribeBlock';
import BlogMainPage from '../components/BlogMainPage';

const ArticlePage = (props) => {
  const { object, global } = props;
  return (
    <Layout {...props}>
      <ArticleHeader data={object} />
      <ArticleBody data={object} />
      <BlogMainPage title="Популярные статьи" link={global.settings.blog_url} data={props.popular_posts} />
      <SubscribeBlock shapes="rounds" />
    </Layout>
  );
};

export default ArticlePage;
