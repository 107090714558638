export const PATHS = {
  home: {
    hash: '#home',
    href_anchor: '/#home',
    feedback_form: '/#feedback',
  },
};

export const TYPES_SERVICE = {
  SEO: 'seo',
  context_ad: 'context_ad',
  crm_integration: 'crm_integration',
};

export const FIELDS_FOR_FORMS = {
  name: {
    name: 'name',
    placeholder: 'Имя',
  },
  phone: {
    name: 'phone',
    placeholder: 'Телефон*',
  },
  email: {
    name: 'email',
    placeholder: 'E-mail',
  },
  comment: {
    name: 'comment',
    placeholder: 'Комментарий',
  },
  site: {
    name: 'site',
    placeholder: 'Ваш сайт*',
  },
  product: {
    name: 'product',
    placeholder: 'Ваш продукт*',
  },
};

export const FORM_TYPES = {
  start_now: 'start_now',
  start_now_case: 'start_now_case',
  footer_request: 'footer_request',
};

export const CALLTOUCH = {
  siteId: '48946',
  modId: 'mm09kwis',
};
