import React from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';
import useCloseModal from '../../../hooks/useCloseModal';

const Modal = ({
  endForm, modalSuccess, modalMessage, addModalMessage, children,
}) => {
  const modalRef = useCloseModal(endForm);

  return (
    <div className={style.modalBg} ref={modalRef}>
      <div className={
        classNames(style.modalContent, { [style.modalContent__withoutBg]: !modalSuccess })
      }
      >
        <button type="button" onClick={endForm} className={style.modalContent_close}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="9"
              y="7.74885"
              width="33"
              height="3"
              transform="rotate(45 9 7.74885)"
              fill="#C41FDF"
              stroke="#C41FDF"
            />
            <rect
              x="6.70711"
              y="31.0417"
              width="33"
              height="3"
              transform="rotate(-45 6.70711 31.0417)"
              fill="#C41FDF"
              stroke="#C41FDF"
            />
          </svg>
        </button>
        <h2 className={style.modalContent__formSuccess}>{modalMessage}</h2>
        {modalSuccess ? (
          <>
            <div className={style.modalContent__addText}>{addModalMessage}</div>
            <button type="button" className={style.modalContent__done} onClick={endForm}>Хорошо</button>
          </>
        ) : (
          <>
            {children}
          </>
        )}
      </div>
    </div>
  );
};

export default Modal;
