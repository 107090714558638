import React from 'react';
import Layout from '../Layout';
import PortfolioFetcher from './Fetchers/PortfolioFetcher';

const PortfolioPage = (props) => {
  const { object } = props;
  return (
    <Layout {...props}>
      <PortfolioFetcher all_tags={object.all_tags} />
    </Layout>
  );
};

export default React.memo(PortfolioPage);
