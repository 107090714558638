import React from 'react';
import style from './index.module.scss';

function FourStage({ object }) {
  const { results } = object;
  return (
    <section className={style.caseStages_four}>
      <div className={style.caseStages_four_name}>
        <p className={style.caseStages_four__num}>04</p>
        <p className={style.caseStages_four__title}>Результаты</p>
      </div>
      <ul className={style.caseStages_four_values}>
        {results.map((item) => (
          <li key={item.id} className={style.caseStages_four_value}>
            <p className={style.caseStages_four_value__name}>
              {item.description}
            </p>
            <p className={style.caseStages_four_value__number}>
              {item.statistics}
            </p>
          </li>
        ))}
      </ul>
    </section>
  );
}

export default FourStage;
