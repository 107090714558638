import React from 'react';
import style from './index.module.scss';
import Squares from '../../CommonViews/Shapes/Squares';
import { PATHS } from '../../../const';

const Header = ({ data }) => {
  return (
    <section className={style.message}>
      <div className={style.message_wrapper}>
        <h2 className={style.message_title}>
          {data.header}
        </h2>
        <div className={style.message_text} dangerouslySetInnerHTML={{ __html: data.text }} />
        <a className={style.button} href={PATHS.home.href_anchor}>Вернуться на главную</a>
      </div>
      <div className={style.squares}>
        <Squares />
      </div>
    </section>
  );
};

export default Header;
