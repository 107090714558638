import React from 'react';
import style from './index.module.scss';
import {
  logo, vk, facebook, instagram, telegram, yadzen,
} from '../../../images';
import { numberRegExp } from '../../../schema';
import Form from '../../CommonViews/Form';
import FooterCopyright from './FooterCopyright';
import { FORM_TYPES } from '../../../const';

const Footer = ({ settings, policy }) => {
  const {
    facebook_link, instagram_link, vk_link, telegram_link, yandex_link, email, phone,
  } = settings;
  return (
    <footer className={style.footer}>
      <div className={style.footer_content}>
        <div className={style.footer_info}>
          <img src={logo} className={style.footer_info__logo} alt="logo" />
          <div className={style.footer_info__links}>
            <a href={`tel:${phone}`} className={style.footer_info__phone}>
              {phone.replace(numberRegExp, '8 ($2) $3-$4-$5')}
            </a>
            <a href={`mailto:${email}`} className={style.footer_info__email}>
              {email}
            </a>
          </div>
          <ul className={style.footer_info__soc}>
            {
              vk_link && (
              <li>
                <a href={vk_link} target="_blank" rel="noreferrer">
                  <img src={vk} alt="vk" />
                </a>
              </li>
              )
            }
            {
              facebook_link && (
              <li>
                <a href={facebook_link} target="_blank" rel="noreferrer">
                  <img src={facebook} alt="facebook" />
                </a>
              </li>
              )
            }
            {
              instagram_link && (
              <li>
                <a href={instagram_link} target="_blank" rel="noreferrer">
                  <img src={instagram} alt="instagram" />
                </a>
              </li>
              )
            }
            {
              telegram_link && (
              <li>
                <a href={telegram_link} target="_blank" rel="noreferrer">
                  <img src={telegram} alt="telegram" />
                </a>
              </li>
              )
            }
            {
              yandex_link && (
              <li>
                <a href={yandex_link} target="_blank" rel="noreferrer">
                  <img src={yadzen} alt="Яндекс Дзен" />
                </a>
              </li>
              )
            }
          </ul>
        </div>
        <div className={style.form_wrapper}>
          <Form policy={policy} type={FORM_TYPES.footer_request}>
            <p className={style.form__header}>
              Оставить заявку
            </p>
          </Form>
        </div>
      </div>
      <FooterCopyright policy={policy} />
    </footer>
  );
};

export default Footer;
