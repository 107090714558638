import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './index.module.scss';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';

const ServiceAdvantages = ({ advantages, title }) => {
  return (
    <section className={style.serviceAdvantages}>
      <h2 className={style.serviceAdvantages__title}>
        {title}
      </h2>
      <Swiper
        breakpoints={{
          320: {
            direction: 'vertical',
            allowTouchMove: false,
          },
          990: {
            direction: 'horizontal',
            slidesPerView: 3,
            allowTouchMove: true,
          },
        }}
      >
        {
          advantages.map(({
            id, title, text, icon,
          }) => {
            return (
              <SwiperSlide key={id} className={style.serviceAdvantages_list_item}>
                <div className={style.serviceAdvantages_list_item__icon}>
                  <img src={icon} alt={title} />
                </div>
                <h3 className={style.serviceAdvantages_list_item__title}>
                  {title}
                </h3>
                <p className={style.serviceAdvantages_list_item__subtitle} dangerouslySetInnerHTML={{ __html: text }} />
              </SwiperSlide>
            );
          })
        }
      </Swiper>
    </section>
  );
};

export default ServiceAdvantages;
