import React from 'react';
import style from './index.module.scss';
import Rounds from '../../CommonViews/Shapes/Rounds';

const Header = ({ data }) => {
  return (
    <section className={style.capture_form_header}>
      <div>
        <h2 className={style.capture_form_title}>
          {data.header}
        </h2>
        <div className={style.capture_form_text} dangerouslySetInnerHTML={{ __html: data.text }} />
      </div>
      <div className={style.rounds}>
        <Rounds />
      </div>
    </section>
  );
};

export default Header;
