import React from 'react';
import style from './index.module.scss';
import round from '../../../images/animateElements/round.svg';

const ServiceWhyWe = ({ advantages, title }) => {
  return (
    <section className={style.whyWe} id="whyWe">
      <div className={style.whyWe__row}>
        <div className={style.whyWe__left}>
          <h2 className={style.section_title}>{title}</h2>
          <div className={style.whyWe__rounds}>
            <img src={round} alt="" className={style.whyWe__round1} />
            <img src={round} alt="" className={style.whyWe__round2} />
            <img src={round} alt="" className={style.whyWe__round3} />
            <img src={round} alt="" className={style.whyWe__round4} />
            <img src={round} alt="" className={style.whyWe__round5} />
            <img src={round} alt="" className={style.whyWe__round6} />
            <img src={round} alt="" className={style.whyWe__round7} />
            <img src={round} alt="" className={style.whyWe__round8} />
            <img src={round} alt="" className={style.whyWe__round9} />
          </div>
        </div>
        <div className={style.whyWe__right}>
          <ul className={style.whyWe_list}>
            {
              advantages.map(({
                id, title, text, icon,
              }) => {
                return (
                  <li key={id} className={style.whyWe_list__item}>
                    <div className={style.whyWe_list__item__icon}>
                      <img src={icon} alt={title} />
                    </div>
                    <h3 className={style.whyWe_list__item__title}>
                      {title}
                    </h3>
                    {text ? <div className={style.whyWe_list__item__subtitle} dangerouslySetInnerHTML={{ __html: text }} /> : null}
                  </li>
                );
              })
            }
          </ul>
        </div>
      </div>
    </section>
  );
};

export default ServiceWhyWe;
