import React from 'react';
import { arrow } from '../../../images';
import style from './index.module.scss';
import round from '../../../images/animateElements/round.svg';

const WhatWeDo = ({ data }) => {
  return (
    <section className={style.whatWeDo} id="whatWeDo">
      <div className={style.whatWeDo__row}>
        <div className={style.whatWeDo__left}>
          <h2 className={style.section_title}>
            Чем мы
            <br />
            {' '}
            занимаемся
          </h2>
          <div className={style.whatWeDo__rounds}>
            <img src={round} alt="" className={style.whatWeDo__round1} />
            <img src={round} alt="" className={style.whatWeDo__round2} />
            <img src={round} alt="" className={style.whatWeDo__round3} />
            <img src={round} alt="" className={style.whatWeDo__round4} />
            <img src={round} alt="" className={style.whatWeDo__round5} />
            <img src={round} alt="" className={style.whatWeDo__round6} />
            <img src={round} alt="" className={style.whatWeDo__round7} />
            <img src={round} alt="" className={style.whatWeDo__round8} />
            <img src={round} alt="" className={style.whatWeDo__round9} />
          </div>
        </div>
        <div className={style.whatWeDo__right}>
          <ul className={style.whatWeDo_list}>
            {data.map((item) => (
              <li key={item.id} className={style.whatWeDo_list__item}>
                <a href={item.page_url ? item.page_url : '#'} className={style.whatWeDo_content}>
                  <div>
                    <p className={style.whatWeDo_title}>{item.title}</p>
                    <div className={style.whatWeDo_subtitle} dangerouslySetInnerHTML={{ __html: item.content }} />
                  </div>
                  { item.page_url && (
                  <img src={arrow} alt="arrow" />
                  )}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
};

export default WhatWeDo;
