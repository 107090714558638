const uncorrectEmail = 'Некорректный email';
const uncorrectPhone = 'Некорректный телефон';
const requiredField = 'Обязательное поле';
const shortString = 'Слишком мало символов';
const longString = 'Слишком много символов';
const lardgeString = 'Допускается не более 100 символов';
const lardgeTextarea = 'Допускается не более 1000 символов';

export {
  uncorrectEmail,
  uncorrectPhone,
  requiredField,
  shortString,
  longString,
  lardgeString,
  lardgeTextarea,
};
