import React from 'react';
import style from './index.module.scss';

const WhyGarpix = ({ data, team_img }) => {
  return (
    <section className={style.whyGarpix} id="whyGarpix">
      <h2 className={style.section_title}>Почему мы</h2>
      <div className={style.whyGarpix__img}>
        <img src={team_img} alt="Команда Garpix" />
      </div>
      <ul className={style.whyGarpix_list}>
        {data.map((item) => (
          <li key={item.id} className={style.whyGarpix_list__item}>
            <div className={style.whyGarpix_iconwrap}>
              <div className={style.whyGarpix_icon} />
            </div>
            <p className={style.whyGarpix_title}>{item.title}</p>
            <div className={style.whyGarpix_subtitle} dangerouslySetInnerHTML={{ __html: item.content }} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default WhyGarpix;
