import React from 'react';
import style from './index.module.scss';

function FirstSecondStage({ object }) {
  const { company_about, company_goal } = object;
  return (
    <section className={style.caseStages_firstsecond}>
      <div className={style.caseStages_firstsecond__stage}>
        <p className={style.caseStages_firstsecond__num}>
          01
        </p>
        <p className={style.caseStages_firstsecond__title}>
          О компании
        </p>
        <div className={style.caseStages_firstsecond__subtitle} dangerouslySetInnerHTML={{ __html: company_about }} />
      </div>
      <div className={style.caseStages_firstsecond__stage}>
        <p className={style.caseStages_firstsecond__num}>
          02
        </p>
        <p className={style.caseStages_firstsecond__title}>
          Задача
        </p>
        <div className={style.caseStages_firstsecond__subtitle} dangerouslySetInnerHTML={{ __html: company_goal }} />
      </div>
    </section>
  );
}

export default FirstSecondStage;
