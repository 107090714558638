import React from 'react';
import Helmet from 'react-helmet';
import Header from '../components/LayoutViews/Header';
import Footer from '../components/LayoutViews/Footer';
import '../style/global/index.scss';
import ScrollToTop from '../components/CommonViews/ScrollToTop';

export default ({
  global,
  object,
  children,
}) => {
  const { seo_title, seo_description, seo_keywords } = object;
  const { policy_page, settings, menus } = global;
  return (
    <>
      <Helmet>
        <title>{seo_title}</title>
        <meta name="description" content={seo_description} />
        <meta name="keywords" content={seo_keywords} />
        <meta property="og:title" content={seo_title} />
        <meta property="og:description" content={seo_description} />
      </Helmet>
      <div className="App">
        <ScrollToTop />
        <Header settings={settings} menus={menus} />
        <div className="container">
          {children}
          <Footer settings={settings} policy={policy_page} />
        </div>
      </div>
    </>
  );
};
