import React, { useState } from 'react';
import style from './index.module.scss';
import {
  star, rub, heart, cart, yandex, diagram, dollar, google, like, chart_line, bullhorn, amoCrm, bitrix24, planfix,
} from '../../../images';
import { TYPES_SERVICE } from '../../../const';
import ModalConsultation from '../Modals/ModalConsultation';

const ServiceBanner = ({
  page_info, type, title, policy,
}) => {
  const [isModal, setIsModal] = useState(false);
  const endForm = () => {
    setIsModal(false);
  };
  const openModal = () => {
    setIsModal(true);
  };

  return (
    <section className={style.serviceBanner}>
      <div className={style.serviceBanner_left}>
        <h1 className={style.serviceBanner_left__title}>
          {title}
        </h1>
        <p className={style.serviceBanner_left__subtitle}>
          { page_info }
        </p>
        <button type="button" onClick={openModal} className={style.serviceBanner_left__button}>Заказать консультацию</button>
      </div>
      {
          type === TYPES_SERVICE.SEO
          && (
          <div className={style.serviceBanner_right}>
            <img src={heart} className={style.serviceBanner_right_seo__icon1} alt="Сердце" />
            <img src={star} className={style.serviceBanner_right_seo__icon2} alt="Звезда" />
            <img src={chart_line} className={style.serviceBanner_right_seo__icon3} alt="График" />
            <img src={google} className={style.serviceBanner_right_seo__icon4} alt="Google" />
            <img src={star} className={style.serviceBanner_right_seo__icon5} alt="Звезда" />
            <img src={bullhorn} className={style.serviceBanner_right_seo__icon6} alt="Мегафон" />
            <img src={yandex} className={style.serviceBanner_right_seo__icon7} alt="Яндекс" />
            <img src={heart} className={style.serviceBanner_right_seo__icon8} alt="Сердце" />
            <img src={bullhorn} className={style.serviceBanner_right_seo__icon9} alt="Мегафон" />
            <img src={heart} className={style.serviceBanner_right_seo__icon10} alt="Сердце" />
            <img src={star} className={style.serviceBanner_right_seo__icon11} alt="Звезда" />
          </div>
          )
      }
      {
        type === TYPES_SERVICE.context_ad
          && (
          <div className={style.serviceBanner_right}>
            <img src={star} className={style.serviceBanner_right_context_ad__icon1} alt="Звезда" />
            <img src={rub} className={style.serviceBanner_right_context_ad__icon2} alt="Рубль" />
            <img src={heart} className={style.serviceBanner_right_context_ad__icon3} alt="Сердце" />
            <img src={yandex} className={style.serviceBanner_right_context_ad__icon4} alt="Яндекс" />
            <img src={cart} className={style.serviceBanner_right_context_ad__icon5} alt="Корзина" />
            <img src={star} className={style.serviceBanner_right_context_ad__icon6} alt="Звезда" />
            <img src={diagram} className={style.serviceBanner_right_context_ad__icon7} alt="Диаграмма" />
            <img src={dollar} className={style.serviceBanner_right_context_ad__icon8} alt="Доллар" />
            <img src={google} className={style.serviceBanner_right_context_ad__icon9} alt="Google" />
            <img src={like} className={style.serviceBanner_right_context_ad__icon10} alt="Лайк" />
            <img src={heart} className={style.serviceBanner_right_context_ad__icon11} alt="Сердце" />
            <img src={star} className={style.serviceBanner_right_context_ad__icon12} alt="Звезда" />
          </div>
          )
      }
      {
        type === TYPES_SERVICE.crm_integration
          && (
          <div className={style.serviceBanner_right}>
            <img src={amoCrm} className={style.serviceBanner_right_crm__icon1} alt="AmoCRM" />
            <img src={star} className={style.serviceBanner_right_crm__icon2} alt="Звезда" />
            <img src={heart} className={style.serviceBanner_right_crm__icon3} alt="Сердце" />
            <img src={planfix} className={style.serviceBanner_right_crm__icon4} alt="Planfix" />
            <img src={heart} className={style.serviceBanner_right_crm__icon5} alt="Сердце" />
            <img src={star} className={style.serviceBanner_right_crm__icon6} alt="Звезда" />
            <img src={bitrix24} className={style.serviceBanner_right_crm__icon8} alt="Bitrix24" />
            <img src={heart} className={style.serviceBanner_right_crm__icon9} alt="Сердце" />
            <img src={chart_line} className={style.serviceBanner_right_crm__icon10} alt="График" />
            <img src={heart} className={style.serviceBanner_right_crm__icon11} alt="Сердце" />
            <img src={star} className={style.serviceBanner_right_crm__icon12} alt="Звезда" />
          </div>
          )
      }
      {isModal ? <ModalConsultation modalType={type} policy={policy} endForm={endForm} /> : null}
    </section>
  );
};

export default ServiceBanner;
