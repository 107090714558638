import React from 'react';
import style from './index.module.scss';
import ArticleContent from '../ArticleContent';

function ArticleBlock({ articleBlock = [], index }) {
  return (
    <div className={style.recentArticles_list} key={index}>
      {articleBlock && articleBlock.map(({
        title, description, image, tags, get_absolute_url, id,
      }) => (
        <a key={id} href={get_absolute_url} className={style.recentArticles_list__item}>
          <ArticleContent
            title={title}
            description={description}
            tags={tags}
            projectVariant="article_content_xs"
          />
          {image
            ? (
              <div className={style.recentArticles_list__item_img}>
                <img src={image} alt={title} />
              </div>
            )
            : null}
        </a>
      ))}
    </div>
  );
}

export default ArticleBlock;
