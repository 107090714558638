import React from 'react';
import style from './index.module.scss';
import { telegram, vk, yadzen } from '../../images';
import { numberRegExp } from '../../schema';
import Form from '../CommonViews/Form';
import FooterCopyright from '../LayoutViews/Footer/FooterCopyright';

const Contact = ({
  settings, policy_page, address,
}) => {
  const {
    vk_link, telegram_link, yandex_link, email, phone,
  } = settings;
  return (
    <>
      <div className={style.contact}>
        <div className={style.contact_left}>
          <div className={style.contact_left_links}>
            <h1 className={style.contact__title}>Контакты</h1>
            <a href={`tel:${phone}`} className={style.contact_left__phone}>
              {phone.replace(numberRegExp, '8 ($2) $3-$4-$5')}
            </a>
            <a href={`mailto:${email}`} className={style.contact_left__email}>
              {email}
            </a>
          </div>
          <ul className={style.contact_left_address}>
            {
              address.map(({ id, city, adress }) => (
                <li className={style.contact_left_address__item} key={id}>
                  <h2 className={style.contact_left_address__item__city}>{city}</h2>
                  <p className={style.contact_left_address__item__street}>{adress}</p>
                </li>
              ))
            }
          </ul>
          <ul className={style.contact_left_soc}>
            {
              vk_link && (
                <li className={style.contact_left_soc__item}>
                  <a href={vk_link} target="_blank" rel="noreferrer">
                    <img src={vk} alt="vk" />
                  </a>
                </li>
              )
            }
            {
              telegram_link && (
                <li className={style.contact_left_soc__item}>
                  <a href={telegram_link} target="_blank" rel="noreferrer">
                    <img src={telegram} alt="telegram" />
                  </a>
                </li>
              )
            }
            {
              yandex_link && (
                <li className={style.contact_left_soc__item}>
                  <a href={yandex_link} target="_blank" rel="noreferrer">
                    <img src={yadzen} alt="Яндекс Дзен" />
                  </a>
                </li>
              )
            }
          </ul>
        </div>
        <div className={style.contact_right}>
          <Form policy={policy_page}>
            <p className={style.contact_right__header}>
              Оставить заявку
            </p>
          </Form>
        </div>
      </div>
      <FooterCopyright policy={policy_page} />
    </>
  );
};

export default Contact;
