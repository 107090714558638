import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import { useLocation } from 'react-router-dom';
import { NavHashLink } from 'react-router-hash-link';
import style from './index.module.scss';
import {
  logo, vk, facebook, instagram, telegram,
} from '../../../images';
import { PATHS } from '../../../const';
import { numberRegExp } from '../../../schema';

const Header = ({ settings, menus }) => {
  const {
    facebook_link, instagram_link, vk_link, telegram_link, phone,
  } = settings;
  const { header_menu } = menus;

  const [menuActive, setMenuActive] = useState(false);
  const location = useLocation();

  const burgerClass = classNames(style.menu_burger__header, {
    [style.openMenu]: menuActive,
  });

  const menuBurger = classNames(style.nav_list, { [style.active]: menuActive });
  const menuBurgerLinks = classNames(style.nav_links, {
    [style.active]: menuActive,
  });

  // const regexp = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

  const deactivateMenu = () => {
    setMenuActive(!menuActive);
  };

  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(location.hash);
      if (element) {
        element.scrollIntoView();
      }
    }
  }, []);

  return (
    <>
      <header className={style.header}>
        <div className={style.container}>
          <a href={PATHS.home.href_anchor}>
            <img src={logo} className={style.header_logo} alt="logo" />
          </a>
          {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
          <button className={burgerClass} onClick={deactivateMenu} type="button">
            <span />
          </button>
          <div className={menuBurger}>
            <ul className={style.nav_top}>
              {header_menu.map(({
                id, url, hash, title,
              }) => {
                return (
                  <li className={style.nav_list__item} key={id}>
                    <NavHashLink
                      to={url}
                      onClick={deactivateMenu}
                      className={classNames(style.nav_link, { [style.nav_link_active]: location.hash === hash && location.hash !== '' || location.pathname === url })}
                    >
                      {title}
                    </NavHashLink>
                  </li>
                );
              })}
            </ul>
            <div className={menuBurgerLinks}>
              <ul className={style.nav_links__soc}>
                {vk_link
                  && (
                  <li>
                    <a href={vk_link} target="_blank" rel="noreferrer">
                      <img src={vk} alt="vk" />
                    </a>
                  </li>
                  )}
                {facebook_link
                  && (
                  <li>
                    <a href={facebook_link} target="_blank" rel="noreferrer">
                      <img src={facebook} alt="facebook" />
                    </a>
                  </li>
                  )}
                {instagram_link
                  && (
                  <li>
                    <a href={instagram_link} target="_blank" rel="noreferrer">
                      <img src={instagram} alt="instagram" />
                    </a>
                  </li>
                  )}
                {telegram_link
                  && (
                  <li>
                    <a href={telegram_link} target="_blank" rel="noreferrer">
                      <img src={telegram} alt="telegram" />
                    </a>
                  </li>
                  )}
              </ul>
              <a className={style.nav_phone} href={`tel:${phone}`}>
                {phone.replace(numberRegExp, '8 ($2) $3-$4-$5')}
              </a>
              <a className={style.nav_garpix} href="https://garpix.com/">
                © Группа компаний GARPIX
              </a>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
