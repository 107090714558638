import React from 'react';
import Layout from '../Layout';
import ServiceBanner from '../components/Services/ServiceBanner';
import { TYPES_SERVICE } from '../const';
import ServiceList from '../components/Services/ServiceList';
import ServiceCost from '../components/Services/ServiceCost';
import PromoBlock from '../components/PromoBlock';
import ServiceScope from '../components/Services/ServiceScope';
import PortfolioMain from '../components/PortfolioMain';
import BlogMainPage from '../components/BlogMainPage';

const SeoPage = (props) => {
  const { object, global, posts } = props;
  return (
    <Layout {...props}>
      <ServiceBanner policy={global.policy_page} type={TYPES_SERVICE.SEO} title={object.banner_title} page_info={object.page_info} />
      <ServiceScope
        capabilities={object.capabilities}
        title={object.capabilities_title}
        subtitle={object.capabilities_subtitle}
        list_title="С помощью SEO мы:"
        context_ad_info={object.capabilities_info}
        type="col"
      />
      <ServiceList service_info={object.service_info} service_info_title={object.service_info_title} />
      <PromoBlock type={TYPES_SERVICE.SEO} data={object.banner} policy={global.policy_page} />
      <ServiceCost policy={global.policy_page} type={TYPES_SERVICE.SEO} title={object.price_title} service_info={object.price_info} price={object.price} />
      <PortfolioMain data={object.portfolio} link={global.settings.caselist_url} />
      { posts.length !== 0
                && <BlogMainPage title="Популярные статьи" link={global.settings.blog_url} data={posts} />}
    </Layout>
  );
};

export default SeoPage;
