import React from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';

const ServiceScope = ({
  capabilities, context_ad_info, list_title, title, subtitle, type,
}) => {
  return (
    <section className={classNames([style.serviceScope], { [style.serviceScope__col]: type === 'col' })}>
      <div className={classNames([style.serviceScope_left], { [style.serviceScope_left__col]: type === 'col' })}>
        <h2 className={style.serviceScope_left__title}>{title}</h2>
        {type === 'col' && subtitle && <h3 className={style.serviceScope_left__subtitle}>{subtitle}</h3>}
        <p className={style.serviceScope_left__description}>{context_ad_info}</p>
      </div>
      {list_title ? (
        <h3 className={style.serviceScope_list__title}>
          {list_title}
        </h3>
      ) : null}
      <ol className={classNames([style.serviceScope_right], { [style.serviceScope_right__col]: type === 'col' })}>
        {capabilities.map(({ id, name }) => {
          return (
            <li className={style.serviceScope_right__item} key={id}>
              {name}
            </li>
          );
        })}
      </ol>
    </section>
  );
};

export default ServiceScope;
