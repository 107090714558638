import React from 'react';
import style from './form.module.scss';

const ErrorInputField = ({ text = null }) => {
  return (
    <div className={style.pageform_form__error}>
      {Array.isArray(text) ? (
        text.map((el) => (
          <span key={el}>
            {el}
          </span>
        ))
      )
        : <span>{text}</span>}
    </div>
  );
};

export default ErrorInputField;
