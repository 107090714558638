import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import style from './index.module.scss';
import { TYPES_SERVICE } from '../../../const';
import ModalConsultation from '../Modals/ModalConsultation';

const ServiceCost = ({
  price, service_info, title, type, policy,
}) => {
  const firstSlide = service_info[0];
  const secondSlide = service_info[1];
  const thirdSlide = service_info[2];

  const [isModal, setIsModal] = useState(false);

  const endForm = () => {
    setIsModal(false);
  };
  const openModal = () => {
    setIsModal(true);
  };

  return (
    <section className={style.serviceCost}>
      <div className={style.serviceCost_left}>
        <h2 className={style.serviceCost_left__title} dangerouslySetInnerHTML={{ __html: title }} />
        <p className={style.serviceCost_left__cost}>
          от
          {' '}
          {price}
          {' '}
          ₽/мес
        </p>
        <button type="button" onClick={openModal} className={style.serviceCost_left__button}>
          Заказать
          {' '}
          <span>индивидуальный</span>
          {' '}
          расчет стоимости
        </button>
      </div>
      <div className={style.serviceCost_right}>
        {type === TYPES_SERVICE.SEO
              && (
              <div className={style.serviceCost_right_block}>
                <h2 className={style.serviceCost_right__slide__title}>Что влияет на стоимость</h2>
                <div className={style.serviceCost_right_block__content}>
                  <div dangerouslySetInnerHTML={{ __html: service_info }} />
                </div>
              </div>
              )}
        {
            type === TYPES_SERVICE.context_ad
              && (
              <Swiper
                direction="vertical"
              >
                <SwiperSlide>
                  <div className={style.serviceCost_right__slide}>
                    <h2 className={style.serviceCost_right__slide__title}>{firstSlide.title}</h2>
                    <div className={style.serviceCost_right__slide_block}>
                      <h3 className={style.serviceCost_right__slide_block__num}>01</h3>
                      <div className={style.serviceCost_right__slide_list} dangerouslySetInnerHTML={{ __html: firstSlide.info }} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={style.serviceCost_right__slide}>
                    <h2 className={style.serviceCost_right__slide__title}>{secondSlide.title}</h2>
                    <div className={style.serviceCost_right__slide_block}>
                      <h3 className={style.serviceCost_right__slide_block__num}>02</h3>
                      <div className={style.serviceCost_right__slide_list} dangerouslySetInnerHTML={{ __html: secondSlide.info }} />
                    </div>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className={style.serviceCost_right__slide_third}>
                    <h2 className={style.serviceCost_right__slide__title}>{thirdSlide.title}</h2>
                    <div className={style.serviceCost_right__slide_block}>
                      <div className={style.serviceCost_right__slide_list_third} dangerouslySetInnerHTML={{ __html: thirdSlide.info }} />
                    </div>
                  </div>
                </SwiperSlide>
              </Swiper>
              )
          }
        {isModal ? <ModalConsultation modalType={type} policy={policy} endForm={endForm} /> : null}
      </div>
    </section>
  );
};
export default ServiceCost;
