import React from 'react';
import HowWeWork from '../components/Main/HowWeWork';
import MainBanner from '../components/Main/MainBanner';
import PageForm from '../components/Main/PageForm';
import PortfolioMain from '../components/PortfolioMain';
import PromoBlock from '../components/PromoBlock';
import WhatWeDo from '../components/Main/WhatWeDo';
import WhyGarpix from '../components/Main/WhyGarpix';

import Layout from '../Layout';
import BlogMainPage from '../components/BlogMainPage';
import { TYPES_SERVICE, FORM_TYPES } from '../const';

const MainPage = (props) => {
  const { object, global } = props;
  return (
    <Layout {...props}>
      <MainBanner slogan={object.slogan} />
      <WhatWeDo data={object.what_we_do} />
      <PageForm typeForm={FORM_TYPES.start_now} {...props} />
      <WhyGarpix data={object.why_we} team_img={object.team_img} />
      <HowWeWork data={object.how_we_work} />
      <PromoBlock data={object.banner} type={TYPES_SERVICE.context_ad} policy={global.policy_page} />
      <PortfolioMain link={global.settings.caselist_url} data={object.portfolio} />
      <BlogMainPage title="Блог" link={global.settings.blog_url} data={object.popular_posts} />
    </Layout>
  );
};

export default MainPage;
