import React from 'react';
import trian from '../../../../images/animateElements/trianurgul.svg';
import style from './index.module.scss';

const Trians = () => {
  return (
    <>
      <img src={trian} alt="" className={style.trian1} />
      <img src={trian} alt="" className={style.trian2} />
      <img src={trian} alt="" className={style.trian3} />
      <img src={trian} alt="" className={style.trian4} />
      <img src={trian} alt="" className={style.trian5} />
      <img src={trian} alt="" className={style.trian6} />
      <img src={trian} alt="" className={style.trian7} />
      <img src={trian} alt="" className={style.trian8} />
      <img src={trian} alt="" className={style.trian9} />
    </>
  );
};

export default Trians;
