import React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import Shapes from '../CommonViews/Shapes';

const Page404 = ({ link }) => {
  return (
    <section className={style.notFound}>
      <div className={style.notFound_content}>
        <h1 className={style.notFound_content__name}>404</h1>
        <p className={style.notFound_content__description}>
          Кажется, это не то, что вы искали. Лучше вернитесь на Главную или почитайте наши свежие статьи.
        </p>
        <Link to={link} className={style.notFound_content__link}>
          <svg width="72" height="33" viewBox="0 0 72 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="16.0146" width="70" height="1" fill="#C41FDF" />
            <rect width="23" height="1" transform="matrix(0.707106 0.707108 -0.707106 0.707108 54.707 0.0146484)" fill="#C41FDF" />
            <rect x="54" y="32.2781" width="23.3137" height="1" transform="rotate(-45 54 32.2781)" fill="#C41FDF" />
          </svg>
          Читать статьи
        </Link>
        <Link to="/" className={style.notFound_content__link}>
          <svg width="72" height="33" viewBox="0 0 72 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect y="16.0146" width="70" height="1" fill="#C41FDF" />
            <rect width="23" height="1" transform="matrix(0.707106 0.707108 -0.707106 0.707108 54.707 0.0146484)" fill="#C41FDF" />
            <rect x="54" y="32.2781" width="23.3137" height="1" transform="rotate(-45 54 32.2781)" fill="#C41FDF" />
          </svg>
          Вернуться на главную
        </Link>
      </div>
      <div className={style.notFound_bg}>
        <div className={style.notFound_bg__squares}>
          <Shapes.Squares />
        </div>
        <div className={style.notFound_bg__rounds}>
          <Shapes.Rounds />
        </div>
        <div className={style.notFound_bg__trians}>
          <Shapes.Trians />
        </div>
      </div>
    </section>
  );
};

export default Page404;
