export const handingErrors = (response) => {
  const value = response.data;
  let errorsObj = {};
  if (response.status === 500) {
    errorsObj = { non_field_errors: ['Произошла ошибка на сервере. Попробуйте позже'] };
  } else {
    errorsObj = value;
  }

  return errorsObj;
};

export const initBlocks = (itemsArr = []) => {
  const itemsBlock = [];
  if (itemsArr.length > 0) {
    for (let index = 0; index < itemsArr.length; index += 3) {
      itemsBlock.push(itemsArr.slice(index, index + 3));
    }
  }
  return itemsBlock;
};

export const deleteSpaces = (value) => {
  if (value) {
    return value.toString().trimStart().trimEnd();
  }
  return value;
};
