import React, { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import style from './index.module.scss';
import ArticleContent from '../ArticleContent';
import api from '../../../api';
import { handingErrors } from '../../../utils';
import { close } from '../../../images';
import { FIELDS_FOR_FORMS } from '../../../const';
import Captcha from '../../CommonViews/Captcha';
import useCloseModal from '../../../hooks/useCloseModal';

const BlogHeader = ({ popularPosts }) => {
  const leftPost = popularPosts[0];
  const rightPost = popularPosts[1];

  const [isModal, setIsModal] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAddMessage, setAddModalMessage] = useState([]);

  const reRef = useRef();

  const errorHandler = (data, setErrors = () => { }) => {
    if (Object.keys(data).length > 0) {
      const errResponse = handingErrors(data);
      setErrors(errResponse);
    }
    setHasErrors(true);
    setIsModal(true);
    setModalMessage(data.data.email);
  };

  const postForm = (data, resetForm = () => {}, setErrors = () => {}) => {
    api.subscribeRequest(data)
      .then(() => {
        setHasErrors(false);
        setIsModal(true);
        setModalMessage('Спасибо!');
        setAddModalMessage([
          'Вы только что подписались на рассылку полезных материалов о продвижении в интернете от Garpix Media.',
          'Обещаем рассказывать только о самом важном и интересном и не беспокоить по пустякам.\n',
          'Мы рады, что вы с нами!\n',
          'Подписывайтесь на страницы Garpix Media в соцсетях, будьте в курсе последних новостей мира digital!\n']);
        resetForm();
      })
      .catch((e) => {
        errorHandler(e.response, setErrors);
      });
  };

  const onSubmit = async (data, { resetForm, setErrors }) => {
    setIsCaptcha(true);
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    if (token) {
      postForm(data, resetForm, setErrors);
    }
  };

  const endForm = () => {
    setIsCaptcha(false);
    setIsModal(false);
    setModalMessage('');
    setAddModalMessage([]);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
  });

  const modalRef = useCloseModal(endForm);

  return (
    <section className={style.blogHeader}>
      <h1 className={style.blogHeader__title}>Блог</h1>
      {leftPost && (
      <div className={style.blogHeader_articles}>
        <Link to={leftPost.get_absolute_url} className={style.blogHeader_articles__left}>
          {leftPost.image
            ? (
              <div className={style.blogHeader_articles__left__image}>
                <img src={leftPost.image} alt={leftPost.title} />
              </div>
            )
            : null}
          <ArticleContent title={leftPost.title} description={leftPost.description} tags={leftPost.tags} blogHeader />
          <div className={style.blogHeader_articles__left__arrow}>
            <svg width="77" height="33" viewBox="0 0 77 33" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect y="16" width="75.3672" height="1" rx="0.5" fill="#C41FDF" />
              <rect width="23.898" height="1.03904" rx="0.519522" transform="matrix(0.732714 0.680537 -0.732714 0.680537 58.9014 0)" fill="#C41FDF" />
              <rect width="24.224" height="1.03904" rx="0.519522" transform="matrix(0.732715 -0.680535 0.732715 0.680535 58.1396 32.2634)" fill="#C41FDF" />
            </svg>
          </div>
        </Link>
        <div className={style.blogHeader_articles__right}>
          <form className={style.blogHeader_articles__right_subscribe} onSubmit={formik.handleSubmit}>
            <input
              type="email"
              id={FIELDS_FOR_FORMS.email.name}
              name={FIELDS_FOR_FORMS.email.name}
              value={formik.values.email || ''}
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
              className={style.blogHeader_articles__right_subscribe__input}
              placeholder="Введите E-mail"
            />
            {isCaptcha
              && <div className={style.captcha}><Captcha ref={reRef} /></div>}
            <button type="submit" className={style.blogHeader_articles__right_subscribe__button}>
              Подписаться
              <span>на блог</span>
            </button>
          </form>
          {rightPost
            && (
            <Link to={rightPost.get_absolute_url} className={style.blogHeader_articles__right_item}>
              <div className={style.blogHeader_articles__right_item__img}>
                <img src={rightPost.image} alt={rightPost.title} />
              </div>
              <ArticleContent title={rightPost.title} description={rightPost.description} tags={rightPost.tags} />
            </Link>
            )}
        </div>
      </div>
      )}
      {isModal && (
        <div className={style.modalBg} ref={modalRef}>
          <div className={style.modalContent}>
            <button
              type="button"
              onClick={endForm}
              className={style.modalContent_close}
            >
              <img src={close} alt="Кнопка закрытия" />
            </button>
            <div className={style.modalContent__formSuccess}>
              {modalMessage}
            </div>
            <div>
              {modalAddMessage.map((message) => <p className={style.modalContent__formMessage}>{message}</p>)}
            </div>
            <button
              type="button"
              onClick={endForm}
              className={style.modalContent_endForm}
            >
              {hasErrors ? 'Закрыть' : 'Хорошо'}
            </button>
          </div>
        </div>
      )}
    </section>
  );
};

export default BlogHeader;
