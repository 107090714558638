import React from 'react';
import {
  BrowserRouter as Router, Switch, Route,
} from 'react-router-dom';
import { StoreContext } from 'storeon/react';
import { store } from './store';
import Combine from './Combine';

function App() {
  return (
    <StoreContext.Provider value={store}>
      <Router>
        <Switch>
          <Route
            path="*"
            render={(props) => <Combine {...props} />}
          />
        </Switch>
      </Router>
    </StoreContext.Provider>
  );
}

export default App;
