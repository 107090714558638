import React from 'react';
import style from './index.module.scss';
import Shapes from '../../CommonViews/Shapes';

function CaseBanner(props) {
  const { tags, company_name, logo } = props.object;
  return (
    <section className={style.caseBanner}>
      <div className={style.caseBanner_content}>
        <div className={style.caseBanner_content__tags}>
          {tags.map((tag) => <p key={tag?.id}>{`#${tag?.name} `}</p>)}
        </div>
        <div className={style.caseBanner_content__container}>
          <h1 className={style.caseBanner_content__name}>{company_name}</h1>
          <div className={style.caseBanner__rounds}>
            <Shapes.Rounds />
          </div>
        </div>
      </div>

      {logo ? <img src={logo} className={style.caseBanner_img} alt={company_name} /> : null}
    </section>
  );
}

export default CaseBanner;
