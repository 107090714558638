import { useCallback, useEffect, useRef } from 'react';

const useCloseModal = (closeModalFn) => {
  const ref = useRef();

  function clickModalFn(e) {
    if (this.isSameNode(e.target)) {
      closeModalFn(e);
    }
  }

  function escKeydownFn(e) {
    if (e.key === 'Escape') {
      closeModalFn(e);
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', escKeydownFn, false);

    return () => {
      document.removeEventListener('keydown', escKeydownFn, false);
    };
  });

  return useCallback((el) => {
    if (ref.current) {
      ref.current.removeEventListener('click', clickModalFn, false);
    }

    if (el) {
      ref.current = el;
      ref.current.addEventListener('click', clickModalFn, false);
    }
  });
};

export default useCloseModal;
