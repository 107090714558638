import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import TagList from '../../CommonViews/TagList';
import ArticleBlock from '../ArticlesBlock';
import { initBlocks } from '../../../utils';
import Loader from '../../CommonViews/Loader';

const RecentArticles = ({
  tags, loadData, results, showMore, isNext, filter_param, status,
}) => {
  const [articlesBlocks, setArticlesBlocks] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState(filter_param === undefined ? '' : filter_param);

  useEffect(() => {
    setArticlesBlocks(initBlocks(results));
  }, [results]);

  useEffect(() => {
    loadData(page, { search: filter }, true);
  }, [filter, page]);

  const changeFilter = (tag) => {
    setPage(1);
    setFilter(tag);
  };
  return (
    <section className={style.recentArticles}>
      <h2 className={style.section_title}>{filter_param ? 'Блог' : 'Последние статьи' }</h2>
      <TagList tags={tags ? Object.values(tags) : []} changeFilter={changeFilter} filter={filter} name="статьи" />
      {status !== 'loaded' ? <Loader />
        : articlesBlocks.length > 0 ? articlesBlocks.map((articleBlock) => <ArticleBlock key={articleBlock.id} articleBlock={articleBlock} />)
          : <h2>Статей нет</h2>}
      {
                status === 'loaded' && isNext && (
                <button onClick={showMore} type="button" className={style.recentArticles_more}>
                  Еще статьи
                </button>
                )
              }
    </section>
  );
};

export default RecentArticles;
