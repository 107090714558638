import React from 'react';
import { Link } from 'react-router-dom';
import { arrow } from '../../images';
import style from './index.module.scss';

const PortfolioMain = ({ data, link }) => {
  return (
    <section className={style.portfolio} id="portfolio">
      <Link to={link} className={style.section_title}>Портфолио</Link>
      <ul className={style.portfolio_list}>
        {data.map(({
          id, company_name, tags, logo, url,
        }) => (
          <li key={id} className={style.portfolio_list__item}>
            <p className={style.portfolio_title}>
              #
              {tags[0].name}
            </p>
            <div className={style.portfolio_content}>
              <Link to={url} className={style.portfolio_name}>{company_name}</Link>
              <div className={style.portfolio_content_inner}>
                {logo
                  ? (
                    <Link to={url}>
                      <img src={logo} className={style.portfolio_content__img} alt={company_name} />
                    </Link>
                  )
                  : null}
                <Link to={url} className={style.portfolio_content_case}>
                  <img src={arrow} className={style.portfolio_content__arrow} alt="portfolio" />
                  <p className={style.portfolio_output}>Кейс</p>
                </Link>
              </div>
            </div>
          </li>
        ))}
      </ul>
      <Link className={style.portfolio__more} to={link}>
        Ещё кейсы
      </Link>
    </section>
  );
};

export default PortfolioMain;
