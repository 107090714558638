import React from 'react';
import './index.scss';
import { marks, vk } from '../../../images';

function ArticleBody({ data }) {
  const {
    content_start, content_end, quote, quote_author, quote_author_position, quote_img,
  } = data;

  return (
    <section className="article_body">
      <div dangerouslySetInnerHTML={{ __html: content_start }} />
      {quote && (
      <div className="quote">
        <img className="quote__marks" src={marks} alt="Цитата" />
        <div className="quote_content">
          <p className="quote_content__text" dangerouslySetInnerHTML={{ __html: quote }} />
          <div className="quote_content_author">
            {quote_img ? <img src={quote_img} alt="Автор цитаты" /> : null}
            <div className="quote_content_author_info">
              <span className="quote_content_author_info__name">{quote_author}</span>
              <span className="quote_content_author_info__post">{quote_author_position}</span>
            </div>
          </div>
        </div>
      </div>
      ) }
      {content_end && <div dangerouslySetInnerHTML={{ __html: content_end }} />}
      <div className="article_body_repost">
        Поделиться:
        <ul className="article_body_repost_soc">
          <li className="article_body_repost_soc__item">
            <a href={`https://vk.com/share.php?url=${document.location.href}`} target="_blank" rel="noopener noreferrer">
              <img src={vk} alt="vk" />
            </a>
          </li>
        </ul>
      </div>
    </section>
  );
}

export default ArticleBody;
