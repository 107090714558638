import React from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';
import Tag from '../../CommonViews/Tag';

const ArticleContent = ({
  projectVariant = 'article_content',
  blogHeader = false,
  title,
  description,
  tags,
  isMainPage = false,
}) => {
  const articleContent = classNames(
    style[projectVariant],
    {
      [style.article_content__big]: blogHeader,
      [style.article_content_mainPage]: isMainPage,
    },
  );

  return (
    <div className={articleContent}>
      <ul className={style[`${projectVariant}_tags`]}>
        {tags && tags.map(({ id, name }) => <Tag name={name} key={id} projectVariant="tag__card" />)}
      </ul>
      <h2 className={style[`${projectVariant}__title`]}>
        {title}
      </h2>
      <p className={style[`${projectVariant}__subtitle`]}>
        {description}
      </p>
    </div>

  );
};

export default ArticleContent;
