import React, { useEffect } from 'react';
import style from './index.module.scss';
import square from '../../../../images/animateElements/square.svg';

function ThirdStage({ object }) {
  useEffect(() => {
    const wrapper = document.createElement('div');
    wrapper.className = `${style.video_container}`;
    const iframe = document.querySelector('iframe');
    if (iframe) {
      iframe.parentNode.insertBefore(wrapper, iframe);
      wrapper.appendChild(iframe);
    }
  }, []);

  const { company_solution } = object;
  return (
    <section className={style.caseStages_third}>
      <div className={style.caseStages_third__background}>
        <div className={style.caseStages_third__squares}>
          <img src={square} alt="" className={style.caseStages_third__square1} />
          <img src={square} alt="" className={style.caseStages_third__square2} />
          <img src={square} alt="" className={style.caseStages_third__square3} />
          <img src={square} alt="" className={style.caseStages_third__square4} />
          <img src={square} alt="" className={style.caseStages_third__square5} />
          <img src={square} alt="" className={style.caseStages_third__square6} />
          <img src={square} alt="" className={style.caseStages_third__square7} />
          <img src={square} alt="" className={style.caseStages_third__square8} />
          <img src={square} alt="" className={style.caseStages_third__square9} />
        </div>
      </div>
      <div className={style.caseStages_third__content}>
        <p className={style.caseStages_third__num}>03</p>
        <div className={style.caseStages_third_substage}>
          <h2 className={style.caseStages_third__name}>Решение</h2>
          <div className={style.caseStages_third__innerHTML} dangerouslySetInnerHTML={{ __html: company_solution }} />
        </div>
      </div>
    </section>
  );
}

export default ThirdStage;
