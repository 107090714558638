import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';
import Tag from '../Tag';

const TagList = ({
  name, tags, filter, changeFilter,
}) => {
  const [showTags, setShowTags] = useState(false);
  const [showButton, setShowButton] = useState(false);

  const showAll = () => {
    setShowTags(!showTags);
  };

  const taglist = useRef(null);

  useEffect(() => {
    if (tags) {
      taglist.current.scrollHeight - 17 > taglist.current.offsetHeight ? setShowButton(true) : setShowButton(false);
    }
  }, [tags]);

  return (
    <div className={classNames(style.menu, { [style.menu__show]: showTags })}>
      <ul className={style.menu__taglist} id="taglist" ref={taglist}>
        <Tag
          name={`Все ${name}`}
          projectVariant="tag"
          isFilterTag
          changeFilter={changeFilter}
          selectAll
          currentTag={!filter}
        />
        {
          tags && tags.map(({ id, name }) => {
            return (
              <Tag
                changeFilter={changeFilter}
                name={name}
                key={id}
                isFilterTag
                projectVariant="tag"
                currentTag={filter === name}
              />
            );
          })
        }
        { showTags && (
        <button onClick={showAll} type="button" className={style.menu__all}>
          <span>Скрыть</span>
          <svg style={{ transform: 'rotate(180deg)' }} width="8" height="27" viewBox="0 0 8 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.64645 26.3536C3.84171 26.5488 4.15829 26.5488 4.35355 26.3536L7.53553 23.1716C7.7308 22.9763 7.7308 22.6597 7.53553 22.4645C7.34027 22.2692 7.02369 22.2692 6.82843 22.4645L4 25.2929L1.17157 22.4645C0.976312 22.2692 0.659729 22.2692 0.464467 22.4645C0.269205 22.6597 0.269205 22.9763 0.464467 23.1716L3.64645 26.3536ZM3.5 2.18557e-08L3.5 26L4.5 26L4.5 -2.18557e-08L3.5 2.18557e-08Z" fill="white" />
          </svg>
        </button>
        ) }
      </ul>
      {showButton && !showTags && (
      <button onClick={showAll} type="button" className={style.menu__all}>
        <p>Все рубрики</p>
        <svg width="8" height="27" viewBox="0 0 8 27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.64645 26.3536C3.84171 26.5488 4.15829 26.5488 4.35355 26.3536L7.53553 23.1716C7.7308 22.9763 7.7308 22.6597 7.53553 22.4645C7.34027 22.2692 7.02369 22.2692 6.82843 22.4645L4 25.2929L1.17157 22.4645C0.976312 22.2692 0.659729 22.2692 0.464467 22.4645C0.269205 22.6597 0.269205 22.9763 0.464467 23.1716L3.64645 26.3536ZM3.5 2.18557e-08L3.5 26L4.5 26L4.5 -2.18557e-08L3.5 2.18557e-08Z" fill="white" />
        </svg>
      </button>
      )}
    </div>
  );
};

export default TagList;
