import React from 'react';
import classNames from 'classnames/bind';
import style from './index.module.scss';

const Tag = ({
  name,
  projectVariant,
  isFilterTag = false,
  changeFilter,
  isArticleTag = false,
  selectAll = false,
  currentTag,
  id,
}) => {
  const handleSelectAll = () => {
    if (selectAll) {
      changeFilter('');
    } else changeFilter(name);
  };

  const cleanUrl = (url = '') => {
    const urlSplit = url.split('/');
    urlSplit.pop();
    return urlSplit.join('/');
  };

  const handleArticleTag = () => {
    const url = cleanUrl(window.location.href);
    document.location.href = `${url}?filter=${name}`;
  };

  const handleTag = () => (isArticleTag ? handleArticleTag() : handleSelectAll());

  return (
    <>
      {isFilterTag
        ? (
          <li key={id}>
            <button
              type="button"
              disabled={currentTag}
              onClick={handleTag}
              className={classNames(style[projectVariant], { [style.active]: currentTag, [style.tag__article]: isArticleTag })}
            >
              {name}
            </button>
          </li>
        )
        : <li key={id} className={style[projectVariant]}>{name}</li>}
    </>
  );
};

export default React.memo(Tag);
