import React from 'react';
import { FetcherList } from '@garpix/fetcher';
import api from '../../api';
import Portfolio from '../../components/Portfolio';

const PortfolioFetcher = ({ all_tags }) => {
  return (
    <FetcherList api={api.getCases} isHistoryPush={false} isScrollTop={false}>
      {({
        results = [], loadData, status, showMore, isNext,
      }) => {
        return (
          <Portfolio
            tags={all_tags}
            results={results}
            loadData={loadData}
            showMore={showMore}
            isNext={isNext}
            status={status}
          />
        );
      }}
    </FetcherList>
  );
};

export default PortfolioFetcher;
