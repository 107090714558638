import React, { useState } from 'react';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input/input';
import style from '../../CommonViews/Modal/index.module.scss';
import api from '../../../api';
import ErrorInputField from '../../CommonViews/Form/ErrorInputField';
import { sendRequest } from '../../../schema';
import Modal from '../../CommonViews/Modal';
import { handingErrors } from '../../../utils';
import { FIELDS_FOR_FORMS, TYPES_SERVICE } from '../../../const';
import calltouch from '../../../utils/metrika';

const ModalConsultation = ({ modalType, endForm, policy }) => {
  const [modalMessage, setModalMessage] = useState('Заказать консультацию');
  const [addModalMessage, setAddModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);

  const onSubmit = (data, { setErrors }) => {
    const ymGoals = {
      [TYPES_SERVICE.SEO]: 'seo',
      [TYPES_SERVICE.context_ad]: 'context_ad',
      [TYPES_SERVICE.crm_integration]: 'crm_integration',
    };
    const calltouchNames = {
      [TYPES_SERVICE.SEO]: 'Заявка консультации по СЕО',
      [TYPES_SERVICE.context_ad]: 'Заявка консультации по контексной рекламе',
      [TYPES_SERVICE.crm_integration]: 'Заявка консультации по CRM',
    };
    const apiFunctions = {
      [TYPES_SERVICE.SEO]: api.seo_consultation,
      [TYPES_SERVICE.context_ad]: api.ad_consultation,
      [TYPES_SERVICE.crm_integration]: api.crm_consultation,
    };

    apiFunctions[modalType]?.call(api, data)
      .then((res) => {
        if (res.status === 201) {
          window.ym(86668920, 'reachGoal', ymGoals[modalType] ?? 'seo');
          calltouch(data, calltouchNames[modalType]);

          setModalSuccess(true);
          setModalMessage('Ваша заявка успешно отправлена!');
          setAddModalMessage('Мы свяжемся с вами в ближайшее время!');
        }
      }).catch((e) => {
        if (Object.keys(e.response).length > 0) {
          const errResponse = handingErrors(e.response);
          setErrors(errResponse);
        } else {
          setModalSuccess(true);
          setModalMessage('Произошла ошибка.');
        }
      });
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
    },
    validationSchema: sendRequest,
    onSubmit,
  });

  const handlePhoneNumber = (value) => {
    formik.setFieldValue(FIELDS_FOR_FORMS.phone.name, value ?? '');
  };

  return (
    <Modal addModalMessage={addModalMessage} modalMessage={modalMessage} modalSuccess={modalSuccess} endForm={endForm}>
      <form onSubmit={formik.handleSubmit}>
        <input
          id={FIELDS_FOR_FORMS.name.name}
          name={FIELDS_FOR_FORMS.name.name}
          type="text"
          className={style.modalContent__input}
          maxLength="50"
          placeholder={FIELDS_FOR_FORMS.name.placeholder}
          value={formik.values.name || ''}
          onInput={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <ErrorInputField text={formik.touched.name && formik.errors.name} />
        <PhoneInput
          value={formik.values.phone || ''}
          international
          onChange={handlePhoneNumber}
          onBlur={formik.handleBlur}
          className={style.modalContent__input}
          placeholder={FIELDS_FOR_FORMS.phone.placeholder}
          id={FIELDS_FOR_FORMS.phone.name}
          name={FIELDS_FOR_FORMS.phone.name}
        />
        <ErrorInputField text={formik.touched.phone && formik.errors.phone} />
        <input
          value={formik.values.email || ''}
          id={FIELDS_FOR_FORMS.email.name}
          name={FIELDS_FOR_FORMS.email.name}
          type="email"
          className={style.modalContent__input}
          maxLength="50"
          placeholder={FIELDS_FOR_FORMS.email.placeholder}
          onInput={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <ErrorInputField text={formik.touched.email && formik.errors.email} />
        <button type="submit" className={style.modalContent_endForm}>Отправить</button>
      </form>
      <p className={style.modalContent__agree}>
        Нажимая на кнопку «Отправить», я соглашаюсь
        <br />
        с
        {' '}
        <a href={policy} target="_blank" rel="noreferrer">политикой конфиденциальности сайта.</a>
      </p>
    </Modal>
  );
};

export default ModalConsultation;
