import React from 'react';
import { Link } from 'react-router-dom';
import style from './index.module.scss';
import ArticleContent from '../Blog/ArticleContent';

const BlogMainPage = ({ link, data, title }) => {
  return (
    <section className={style.blog} id="blog">
      { link
        ? <Link to={link} className={style.section_title}>{title}</Link>
        : <h2 className={style.section_title}>{title}</h2>}
      <div className={style.blog_list}>
        {data && data.map(({
          description, get_absolute_url, id, image, tags, title,
        }) => (
          <Link to={get_absolute_url} className={style.blog_list_item} key={id}>
            {image
              ? (
                <div className={style.blog_list_item__image}>
                  <img src={image} alt={title} />
                </div>
              )
              : null}
            <ArticleContent
              title={title}
              description={description}
              tags={tags}
              projectVariant="article_content_xs"
              isMainPage
            />
          </Link>
        ))}
      </div>
      <Link to={link} className={style.blog_more}>
        Все статьи
      </Link>
    </section>
  );
};

export default BlogMainPage;
