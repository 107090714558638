import React, { useEffect, useState } from 'react';
import style from './index.module.scss';
import TagList from '../CommonViews/TagList';
import { initBlocks } from '../../utils';
import CasesBlock from './CasesBlock';
import Loader from '../CommonViews/Loader';

const Portfolio = ({
  tags, loadData, results, showMore, isNext, status,
}) => {
  const [casesBlocks, setCasesBlocks] = useState([]);
  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState('');

  useEffect(() => {
    setCasesBlocks(initBlocks(results));
  }, [results]);

  useEffect(() => {
    loadData(page, { search: filter }, true);
  }, [filter, page]);

  const changeFilter = (tag) => {
    setPage(1);
    setFilter(tag);
  };

  return (
    <section className={style.portfolioPage}>
      <h1 className={style.section_title}>Портфолио</h1>
      <TagList tags={tags} changeFilter={changeFilter} filter={filter} name="кейсы" />
      {status !== 'loaded' ? <Loader />
        : casesBlocks.length > 0 ? casesBlocks.map((casesBlock, index) => {
          return <CasesBlock casesBlock={casesBlock} key={casesBlock.id} index={index} />;
        })
          : <h2>Кейсов нет</h2>}
      {
        status === 'loaded' && isNext
        && (
        <button type="button" onClick={showMore} className={style.portfolioPage_more}>
          Еще кейсы
        </button>
        )
      }
    </section>
  );
};

export default Portfolio;
