import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';

import ErrorInputField from '../../CommonViews/Form/ErrorInputField';
import * as errorsMessenge from '../../../schema/const';
import { handingErrors } from '../../../utils';
import api from '../../../api';
import style from './form.module.scss';
import { FIELDS_FOR_FORMS, PATHS } from '../../../const';

/* eslint-disable jsx-a11y/label-has-associated-control */

const Form = ({ data, children }) => {
  const uuid = new URLSearchParams(window.location.search).get('uuid');
  if (uuid == null) {
    window.location = PATHS.home.feedback_form;
    return null;
  }

  const errorHandler = (data, setErrors = () => { }) => {
    if (Object.keys(data).length > 0) {
      const errResponse = handingErrors(data);
      setErrors(errResponse);
    }
  };

  const postForm = (data, setErrors = () => { }) => {
    api.sendCaptureForm(data)
      .then((result) => {
        window.ym(86668920, 'reachGoal', 'request_capture');

        if (result.redirect_to) {
          window.location = result.redirect_to;
        } else {
          window.location = PATHS.home.href_anchor;
        }
      })
      .catch((e) => {
        if (e.response.status === 404) {
          window.location = PATHS.home.feedback_form;
          return;
        }

        errorHandler(e.response, setErrors);
      });
  };

  const onSubmit = async (data, { setErrors }) => {
    postForm(data, setErrors);
  };

  const validate = () => {
    return Yup.object().shape({
      site: Yup.string()
        .required(errorsMessenge.requiredField),
      product: Yup.string()
        .required(errorsMessenge.requiredField),
    });
  };

  const formik = useFormik({
    initialValues: {
      site: '',
      product: '',
      tasks: [],
      marketing_channels: [],
      budget: '',
      uuid,
    },
    validationSchema: validate,
    onSubmit,
  });

  return (
    <section className={style.pageform}>
      <form className={style.pageform_form} onSubmit={formik.handleSubmit}>
        {children}
        <div className={style.pageform_row}>
          <div className={style.pageform_input}>
            <input
              id={FIELDS_FOR_FORMS.site.name}
              name={FIELDS_FOR_FORMS.site.name}
              type="text"
              className="lk-reg-input"
              placeholder={FIELDS_FOR_FORMS.site.placeholder}
              required
              maxLength={120}
              value={formik.values.site}
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorInputField text={formik.touched.site && formik.errors.site} />
          </div>
          <div className={style.pageform_input}>
            <input
              id={FIELDS_FOR_FORMS.product.name}
              name={FIELDS_FOR_FORMS.product.name}
              type="text"
              className="lk-reg-input"
              placeholder={FIELDS_FOR_FORMS.product.placeholder}
              required
              maxLength={255}
              value={formik.values.product}
              onInput={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <ErrorInputField text={formik.touched.product && formik.errors.product} />
          </div>

          <div className={style.marketing}>
            <p className={style.pageform_text}>
              Какие рекламные каналы сейчас используете?
            </p>
            <fieldset className={style.marketing_channels}>
              {data.marketing_channels.map((channel) => (
                <label key={channel.id}>
                  <input
                    type="checkbox"
                    name="marketing_channels"
                    value={channel.id}
                    onInput={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {channel.name}
                </label>
              ))}
            </fieldset>
            <ErrorInputField text={formik.touched.marketing_channels && formik.errors.marketing_channels} />
          </div>

          <div className={style.task}>
            <p className={style.pageform_text}>
              Какую задачу необходимо решить?
            </p>
            <fieldset className={style.task_items}>
              {data.tasks.map((task) => (
                <label key={task.id}>
                  <input
                    type="checkbox"
                    name="tasks"
                    value={task.id}
                    onInput={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {task.name}
                </label>
              ))}
            </fieldset>
            <ErrorInputField text={formik.touched.tasks && formik.errors.tasks} />
          </div>

          <div className={style.budget}>
            <p className={style.pageform_text}>
              Ваш ежемесячный бюджет на маркетинг:
            </p>
            <fieldset className={style.budget_list}>
              {data.budgets.map((budget) => (
                <label key={budget.id}>
                  <input
                    type="radio"
                    name="budget"
                    value={budget.id}
                    onInput={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {budget.name}
                </label>
              ))}
            </fieldset>
            <ErrorInputField text={formik.touched.budget && formik.errors.budget} />
          </div>
        </div>
        <button type="submit" className={style.button}>
          Отправить
        </button>
      </form>
    </section>
  );
};

export default Form;
