import React, { useState, useRef } from 'react';
import { useFormik } from 'formik';
import PhoneInput from 'react-phone-number-input/input';

import ErrorInputField from './ErrorInputField';
import { sendRequest } from '../../../schema';
import { handingErrors } from '../../../utils';
import Captcha from '../Captcha';
import api from '../../../api';
import { close } from '../../../images';
import { FIELDS_FOR_FORMS, FORM_TYPES } from '../../../const';
import style from './form.module.scss';
import useCloseModal from '../../../hooks/useCloseModal';
import calltouch from '../../../utils/metrika';

const Form = ({ policy = '/', type = '', children }) => {
  const [isModal, setIsModal] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAddMessage, setAddModalMessage] = useState('');

  const reRef = useRef();

  const getUtmId = async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const utmSource = urlParams.get('utm_source');

    const formDataUtm = new FormData();
    formDataUtm.append('utm_label', queryString.substring(1));
    formDataUtm.append('source_name', utmSource);

    let result = null;
    await api.create_utm(formDataUtm).then((data) => {
      if (data.id) {
        result = data.id;
      }
      return false;
    });

    return result;
  };

  const errorHandler = (data, setErrors = () => { }) => {
    if (Object.keys(data).length > 0) {
      const errResponse = handingErrors(data);
      setErrors(errResponse);
    } else {
      setHasErrors(true);
      setIsModal(true);
      setModalMessage('Ваша заявка не была отправлена');
      setAddModalMessage('Попробуйте еще раз');
    }
  };

  const postForm = (data, resetForm = () => { }, setErrors = () => { }) => {
    api.sendRequest(data)
      .then((result) => {
        const ymGoals = {
          [FORM_TYPES.footer_request]: 'Request',
          [FORM_TYPES.start_now]: 'Request_now',
          [FORM_TYPES.start_now_case]: 'request_case',
        };
        const calltouchNames = {
          [FORM_TYPES.footer_request]: 'Заявка с футера',
          [FORM_TYPES.start_now]: 'Заявка "Начните работу сейчас"(с главной)',
          [FORM_TYPES.start_now_case]: 'Заявка "Начните работу сейчас"(с кейса)',
        };
        window.ym(86668920, 'reachGoal', ymGoals[type] ?? 'Request_now');

        if (result.redirect_to) {
          calltouch(data, calltouchNames[type]).then(() => {
            window.location = result.redirect_to;
          });
          return;
        }

        calltouch(data, calltouchNames[type]);
        setHasErrors(false);
        setIsModal(true);
        setModalMessage('Ваша заявка успешно отправлена!');
        setAddModalMessage('Мы свяжемся с вами в ближайшее время!');
        resetForm();
      })
      .catch((e) => {
        errorHandler(e.response, setErrors);
      });
  };

  const onSubmit = async (data, { resetForm, setErrors }) => {
    const utmId = await getUtmId();
    const formData = data;
    if (utmId) {
      formData.utm = utmId;
    }
    setIsCaptcha(true);
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    if (token) {
      postForm(formData, resetForm, setErrors);
    }
  };

  const endForm = () => {
    setIsCaptcha(false);
    setIsModal(false);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      email: '',
      comment: '',
    },
    validationSchema: sendRequest,
    onSubmit,
  });

  const handlePhoneNumber = (value) => {
    formik.setFieldValue(FIELDS_FOR_FORMS.phone.name, value ?? '');
  };

  const modalRef = useCloseModal(endForm);

  return (
    <form className={style.pageform_form} onSubmit={formik.handleSubmit}>
      {children}
      <input
        id={FIELDS_FOR_FORMS.name.name}
        name={FIELDS_FOR_FORMS.name.name}
        type="text"
        className="lk-reg-input"
        maxLength="50"
        placeholder={FIELDS_FOR_FORMS.name.placeholder}
        value={formik.values.name || ''}
        onInput={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <ErrorInputField text={formik.touched.name && formik.errors.name} />
      <PhoneInput
        value={formik.values.phone}
        international
        onChange={handlePhoneNumber}
        onBlur={formik.handleBlur}
        className="lk-reg-input"
        placeholder={FIELDS_FOR_FORMS.phone.placeholder}
        id={FIELDS_FOR_FORMS.phone.name}
        name={FIELDS_FOR_FORMS.phone.name}
      />
      <ErrorInputField text={formik.touched.phone && formik.errors.phone} />
      <input
        value={formik.values.email || ''}
        id={FIELDS_FOR_FORMS.email.name}
        name={FIELDS_FOR_FORMS.email.name}
        type="email"
        className="lk-reg-input"
        maxLength="50"
        placeholder={FIELDS_FOR_FORMS.email.placeholder}
        onInput={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <ErrorInputField text={formik.touched.email && formik.errors.email} />
      <textarea
        value={formik.values.comment || ''}
        className={style.pageform_form__comment}
        placeholder={FIELDS_FOR_FORMS.comment.placeholder}
        name={FIELDS_FOR_FORMS.comment.name}
        id={FIELDS_FOR_FORMS.comment.name}
        onInput={formik.handleChange}
        onBlur={formik.handleBlur}
      />
      <ErrorInputField text={formik.touched.comment && formik.errors.comment} />
      {isCaptcha && <Captcha ref={reRef} />}
      <button type="submit" className={style.button}>
        Отправить заявку
      </button>
      <p className={style.agree_text}>
        Нажимая на кнопку «Отправить», я соглашаюсь
        {' '}
        <br />
        {' '}
        с&nbsp;
        <a className={style.agree_text__policy} href={policy} target="_blank" rel="noreferrer">
          политикой конфиденциальности сайта.
        </a>
      </p>

      {isModal && (
        <div className={style.modalBg} ref={modalRef}>
          <div className={style.modalContent}>
            <button
              type="button"
              onClick={endForm}
              className={style.modalContent_close}
            >
              <img src={close} alt="Кнопка закрытия" />
            </button>
            <div className={style.modalContent__formSuccess}>
              {modalMessage}
            </div>
            <div className={style.modalContent__formMessage}>
              {modalAddMessage}
            </div>
            <button
              type="button"
              onClick={endForm}
              className={style.modalContent_endForm}
            >
              {hasErrors ? 'Закрыть' : 'Хорошо'}
            </button>
          </div>
        </div>
      )}
    </form>
  );
};

export default Form;
