import BaseApi from '@garpix/base-api';
import { sendRequestCapture, sendRequestConsultation, sendRequestSer } from './serializer';

export const MAIN_URL = process.env.REACT_APP_MAIN_URL;
export const CAPTCHA_KEY = process.env.REACT_APP_CAPTCHA_KEY;

class Api extends BaseApi {
  axiosOverride = (axios) => {
    return axios;
  }

  getPage = async (params = {}) => {
    try {
      const response = await fetch(`${MAIN_URL}/api/page${params[0]}`);
      const result = await response.json();
      if (!result.page_model && !result.init_state.object || result?.init_state?.object?.is_active === false) {
        const response_404 = await fetch(`${MAIN_URL}/api/page/404`);
        const result404 = await response_404.json();
        return { ...result404 };
      }
      return { ...result };
    } catch (error) {
      return { page_model: 'Page500' };
    }
  };

  getArticles = async (params) => {
    const response = await this.get('/api/v1/blog/filter/', params);
    return response.data;
  };

  getCases = async (params) => {
    const response = await this.get('/api/v1/case/filter/', params);
    return response.data;
  };

  unsubscribeUser = (email) => {
    return this.get(`/api/page/unsubscribe?email=${email}`, []);
  };

  async sendRequest(params) {
    const paramsSer = sendRequestSer(params);
    const res = await this.post('/api/v1/feedback/', paramsSer);
    return res.data;
  }

  async sendCaptureForm(params) {
    const paramsSer = sendRequestCapture(params);
    const res = await this.patch(`/api/v1/feedback/${params.uuid}/`, paramsSer);
    return res.data;
  }

  async subscribeRequest(params) {
    const res = await this.post('/api/v1/blog/subscribe/', params);
    return res.data;
  }

  async contextAd_lead_magnet(data) {
    const res = await this.post('/api/v1/lead_magnet/', data);
    return res;
  }

  async seo_lead_magnet(data) {
    const res = await this.post('/api/v1/seo/lead_magnet/', data);
    return res;
  }

  async seo_consultation(data) {
    const params = sendRequestConsultation(data);
    const res = await this.post('/api/v1/seo/consultation/', params);
    return res;
  }

  async ad_consultation(data) {
    const params = sendRequestConsultation(data);
    const res = await this.post('/api/v1/services/consultation/', params);
    return res;
  }

  async crm_consultation(data) {
    const params = sendRequestConsultation(data);
    const res = await this.post('/api/v1/services/crm-consultation/', params);
    return res;
  }

  async create_utm(data) {
    const res = await this.post('/api/v1/utm/create/', data);
    return res.data;
  }
}

const api = new Api(`${MAIN_URL}`);
export default api;
