import { parsePhoneNumber } from 'react-phone-number-input';
import * as Yup from 'yup';
import * as errorsMessenge from './const';

const emailRegExp = /^([a-zA-Z0-9_-]+\.)*[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)*\.[a-zA-Z]{2,6}$/;
export const numberRegExp = /(\+7|8)[\s(]?(\d{3})[\s)]?(\d{3})[\s-]?(\d{2})[\s-]?(\d{2})/g;

function phoneValidator(message, availableCountries = []) {
  return this.test({
    message,
    test: (value) => {
      if (value == null) {
        return false;
      }

      const phoneNumber = parsePhoneNumber(value);
      if (phoneNumber) {
        return phoneNumber.isValid() && availableCountries.includes(phoneNumber.country);
      }

      return false;
    },
  });
}
Yup.addMethod(Yup.string, 'phone', phoneValidator);

const sendRequest = () => {
  return Yup.object().shape({
    name: Yup.string()
      .max(100, errorsMessenge.lardgeString),
    phone: Yup.string()
      .nullable()
      .required(errorsMessenge.requiredField)
      .phone(errorsMessenge.uncorrectPhone, ['RU', 'BY']),
    email: Yup.string()
      .matches(emailRegExp, errorsMessenge.uncorrectEmail),
    comment: Yup.string()
      .max(1000, errorsMessenge.lardgeTextarea),
  });
};

export {
  sendRequest,
};
