import React from 'react';
import style from './index.module.scss';

const ServiceIntegrationPlans = ({ integrations, title }) => {
  const integration_plans = [];
  for (let i = 0, len = Object.keys(integrations).length; i < len; i += 3) {
    integration_plans.push(integrations.slice(i, 3 + i));
  }

  return (
    <section className={style.integrationPlans} id="integrationPlans">
      <div className={style.integrationPlans__row}>
        <h2 className={style.section_title}>{title}</h2>
        <ul className={style.integrationPlans_list}>
          {
            integration_plans.map((chunk, index) => {
              return (
                <>
                  {chunk.map(({ id, title }) => {
                    return (
                      <li key={id} className={style.integrationPlans_list__item}>
                        <h3 className={style.integrationPlans_list__item__title}>
                          {title}
                        </h3>
                      </li>
                    );
                  })}
                  {index + 1 !== integration_plans.length ? <span className={style.integrationPlans_list__item_arrow} /> : null}
                </>
              );
            })
          }
        </ul>
      </div>
    </section>
  );
};

export default ServiceIntegrationPlans;
