import React from 'react';
import { FetcherList } from '@garpix/fetcher';
import api from '../../api';
import RecentArticles from '../../components/Blog/RecentArticles';

const BlogFetcher = ({ all_tags, filter_param }) => {
  return (
    <FetcherList api={api.getArticles} isHistoryPush={false} isScrollTop={false}>
      {({
        results = [], loadData, status, showMore, isNext,
      }) => {
        return (
          <RecentArticles
            tags={all_tags}
            filter_param={filter_param}
            loadData={loadData}
            results={results}
            showMore={showMore}
            isNext={isNext}
            status={status}
          />
        );
      }}
    </FetcherList>
  );
};

export default BlogFetcher;
