import React, { useRef, useState } from 'react';
import { useFormik } from 'formik';
import style from './index.module.scss';
import Shapes from '../../CommonViews/Shapes';
import { handingErrors } from '../../../utils';
import api from '../../../api';
import { close } from '../../../images';
import { FIELDS_FOR_FORMS } from '../../../const';
import Captcha from '../../CommonViews/Captcha';
import useCloseModal from '../../../hooks/useCloseModal';

const SubscribeBlock = ({ shapes }) => {
  const [isModal, setIsModal] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const [isCaptcha, setIsCaptcha] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalAddMessage, setAddModalMessage] = useState([]);

  const reRef = useRef();

  const errorHandler = (data, setErrors = () => { }) => {
    if (Object.keys(data).length > 0) {
      const errResponse = handingErrors(data);
      setErrors(errResponse);
    }
    setHasErrors(true);
    setIsModal(true);
    setModalMessage(data.data.email);
  };

  const postForm = (data, resetForm = () => {}, setErrors = () => {}) => {
    api.subscribeRequest(data)
      .then(() => {
        setHasErrors(false);
        setIsModal(true);
        setModalMessage('Спасибо!');
        setAddModalMessage([
          'Вы только что подписались на рассылку полезных материалов о продвижении в интернете от Garpix Media.',
          'Обещаем рассказывать только о самом важном и интересном и не беспокоить по пустякам.\n',
          'Мы рады, что вы с нами!\n',
          'Подписывайтесь на страницы Garpix Media в соцсетях, будьте в курсе последних новостей мира digital!\n']);
        resetForm();
      })
      .catch((e) => {
        errorHandler(e.response, setErrors);
      });
  };

  const onSubmit = async (data, { resetForm, setErrors }) => {
    setIsCaptcha(true);
    const token = await reRef.current.executeAsync();
    reRef.current.reset();
    if (token) {
      postForm(data, resetForm, setErrors);
    }
  };

  const endForm = () => {
    setIsCaptcha(false);
    setIsModal(false);
  };

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit,
  });

  const modalRef = useCloseModal(endForm);

  return (
    <section className={style.subscribeBlock}>
      <div className={style.subscribeBlock_left}>
        <h2 className={style.subscribeBlock_left__title}>Читайте полезные материалы первым!</h2>
        <form className={style.subscribeBlock_left_form} onSubmit={formik.handleSubmit}>
          <input
            className={style.subscribeBlock_left_form__input}
            placeholder="Введите E-mail"
            type="email"
            id={FIELDS_FOR_FORMS.email.name}
            name={FIELDS_FOR_FORMS.email.name}
            value={formik.values.email || ''}
            onInput={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          {isCaptcha && <Captcha ref={reRef} />}
          <button type="submit" className={style.subscribeBlock_left_form__button}>Подписаться</button>
        </form>
        {isModal && (
          <div className={style.modalBg} ref={modalRef}>
            <div className={style.modalContent}>
              <button
                type="button"
                onClick={endForm}
                className={style.modalContent_close}
              >
                <img src={close} alt="Кнопка закрытия" />
              </button>
              <div className={style.modalContent__formSuccess}>
                {modalMessage}
              </div>
              <div>
                {modalAddMessage.map((message) => <p className={style.modalContent__formMessage}>{message}</p>)}
              </div>
              <button
                type="button"
                onClick={endForm}
                className={style.modalContent_endForm}
              >
                {hasErrors ? 'Закрыть' : 'Хорошо'}
              </button>
            </div>
          </div>
        )}
      </div>
      {
        shapes === 'squares' && (
          <div className={style.subscribeBlock_bg__squares}>
            <Shapes.Squares />
          </div>
        )
      }
      {
        shapes === 'rounds' && (
        <div className={style.subscribeBlock_bg__rounds}>
          <Shapes.Rounds />
        </div>
        )
      }
    </section>
  );
};

export default SubscribeBlock;
