import React from 'react';
import style from './index.module.scss';

const ServiceSetup = ({ ad_blocks, title }) => {
  return (
    <section className={style.serviceSetup}>
      <h2 className={style.serviceSetup__title}>{title}</h2>
      <div className={style.serviceSetup_blocks}>
        {ad_blocks.map(({
          id, name, info, icon,
        }) => {
          return (
            <div className={style.serviceSetup_blocks_item} key={id}>
              <h3 className={style.serviceSetup_blocks_item__title}>{name}</h3>
              <div className={style.serviceSetup_blocks_item_list} dangerouslySetInnerHTML={{ __html: info }} />
              <img src={icon} alt={name} className={style.serviceSetup_blocks_item__img} />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ServiceSetup;
