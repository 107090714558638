import React from 'react';

const Page500 = () => {
  return (
    <div>
      Произошла ошибка на сервере
    </div>
  );
};

export default Page500;
