import React from 'react';
import round from '../../../../images/animateElements/round.svg';
import style from './index.module.scss';

const Rounds = () => {
  return (
    <>
      <img src={round} alt="round1" className={style.round1} />
      <img src={round} alt="round2" className={style.round2} />
      <img src={round} alt="round3" className={style.round3} />
      <img src={round} alt="round4" className={style.round4} />
      <img src={round} alt="round5" className={style.round5} />
      <img src={round} alt="round6" className={style.round6} />
      <img src={round} alt="round7" className={style.round7} />
      <img src={round} alt="round8" className={style.round8} />
      <img src={round} alt="round9" className={style.round9} />
    </>
  );
};

export default Rounds;
