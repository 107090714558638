import React from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { CAPTCHA_KEY } from '../../../api';

const Captcha = React.forwardRef((props, ref) => (
  <ReCAPTCHA
    sitekey={CAPTCHA_KEY}
    theme="dark"
    size="invisible"
    ref={ref}
  />
));

export default Captcha;
