import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import ModalConsultation from '../Modals/ModalConsultation';
import style from './index.module.scss';

const ServiceHowSelect = ({
  how_select, title, description, type, policy,
}) => {
  const how_select_blocks = [];
  for (let i = 0, len = Object.keys(how_select).length; i < len; i += 2) {
    how_select_blocks.push(how_select.slice(i, 2 + i));
  }

  const [isModal, setIsModal] = useState(false);

  const endForm = () => {
    setIsModal(false);
  };
  const openModal = () => {
    setIsModal(true);
  };

  return (
    <section className={style.serviceHowSelect}>
      <div className={style.serviceHowSelect_left}>
        <h2 className={style.serviceHowSelect_left__title} dangerouslySetInnerHTML={{ __html: title }} />
        {description ? (
          <p className={style.serviceHowSelect_left__description}>{description}</p>
        ) : null}
        <button type="button" onClick={openModal} className={style.serviceHowSelect_left__button}>
          Подобрать
          {' '}
          CRM-систему
        </button>
      </div>
      <div className={style.serviceHowSelect_right}>
        <Swiper
          direction="horizontal"
        >
          {how_select_blocks.map((block, index) => {
            return (
              <SwiperSlide>
                <div className={style.serviceHowSelect_right__slide}>
                  {block.map((el, elIndex) => {
                    return (
                      <div className={style.serviceHowSelect_right__slide_block} key={el.id}>
                        <h3 className={style.serviceHowSelect_right__slide_block__num}>
                          0
                          {index + 1 + elIndex + 1 * index}
                        </h3>
                        <div className={style.serviceHowSelect_right__slide_list}>
                          <h2 className={style.serviceHowSelect_right__slide__title}>{el.title}</h2>
                          <div className={style.serviceHowSelect_right__slide__info} dangerouslySetInnerHTML={{ __html: el.info }} />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
        {isModal ? <ModalConsultation modalType={type} policy={policy} endForm={endForm} /> : null}
      </div>
    </section>
  );
};
export default ServiceHowSelect;
