import React from 'react';
import style from './index.module.scss';

const ServiceList = ({ service_info_title, service_info }) => {
  return (
    <section className={style.serviceList}>
      <h2 className={style.serviceList__title}>{service_info_title}</h2>
      <div className={style.serviceList_list} dangerouslySetInnerHTML={{ __html: service_info }} />
    </section>
  );
};

export default ServiceList;
