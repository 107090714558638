import React from 'react';
import style from './index.module.scss';

const Loader = () => {
  return (
    <div className={style.loader} />
  );
};

export default Loader;
