import React from 'react';
import CaseBanner from '../components/Case/CaseBanner';
import FirstSecondStage from '../components/Case/CaseStages/FirstSecondStage';
import FourStage from '../components/Case/CaseStages/FourStage';
import ThirdStage from '../components/Case/CaseStages/ThirdStage';
import PageForm from '../components/Main/PageForm';
import Layout from '../Layout';
import PortfolioMain from '../components/PortfolioMain';
import { FORM_TYPES } from '../const';

const CasePage = (props) => {
  const { object, global } = props;
  return (
    <Layout {...props}>
      <CaseBanner {...props} />
      <FirstSecondStage {...props} />
      <ThirdStage {...props} />
      <FourStage {...props} />
      <PageForm typeForm={FORM_TYPES.start_now_case} {...props} />
      <PortfolioMain link={global.settings.caselist_url} data={object.cases} />
    </Layout>
  );
};

export default CasePage;
