import React, { useEffect } from 'react';
import { Fetcher } from '@garpix/fetcher';
import { useHistory } from 'react-router-dom';
import api from './api';
import CasePage from './pages/CasePage';
import MainPage from './pages/MainPage';
import './style/global/index.scss';
import PageError404 from './pages/PageError404';
import UnsubscribePage from './pages/UnsubscribePage';
import ArticlePage from './pages/ArticlePage';
import BlogPage from './pages/BlogPage';
import PortfolioPage from './pages/PortfolioPage';
import ServicesPage from './pages/ServicesPage';
import Page500 from './pages/Page500';
import SeoPage from './pages/SeoPage';
import CrmIntegrationPage from './pages/CrmIntegrationPage';
import ContactPage from './pages/ContactPage';
import CaptureFormPage from './pages/CaptureFormPage';
import CaptureFormSendPage from './pages/CaptureFormSendPage';

const PAGE_TYPES = {
  MainPage,
  CasePage,
  PostListPage: BlogPage,
  Error404Page: PageError404,
  PreUnsubPage: UnsubscribePage,
  CaseListPage: PortfolioPage,
  PostPage: ArticlePage,
  ContextAdPage: ServicesPage,
  FeedbackPage: CaptureFormPage,
  FeedbackSendPage: CaptureFormSendPage,
  SeoPage,
  CrmIntegrationPage,
  Page500,
  ContactPage,
};

function Combine(props) {
  const history = useHistory();

  useEffect(() => {
    const data = window.location.origin + history.location.pathname;
    window.ym(86668920, 'hit', data);
  }, [history.location.pathname]);

  return (
    <Fetcher {...props} paramsKey="0" fetchInitialData={api.getPage}>
      {(data) => {
        if (!data) return null;
        const { page_model, init_state } = data;
        const Page = PAGE_TYPES[page_model];
        return <Page {...init_state} />;
      }}
    </Fetcher>
  );
}

export default Combine;
