import React from 'react';
import Layout from '../Layout/FooterLess';
import Header from '../components/CaptureFormPage/Header';
import Form from '../components/CaptureFormPage/Form';

const CaptureFormPage = (props) => {
  const { object } = props;
  return (
    <Layout {...props}>
      <Header data={object} />
      <Form data={object} />
    </Layout>
  );
};

export default CaptureFormPage;
