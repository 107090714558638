import React, { useState } from 'react';
import qs from 'query-string';
import style from './index.module.scss';
import Shapes from '../CommonViews/Shapes';
import api from '../../api';
import Modal from '../CommonViews/Modal';

const Unsubscribe = () => {
  const [isModal, setIsModal] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const userEmail = qs.parseUrl(window.location.href).query?.email;

  const endForm = () => {
    setIsModal(false);
    setModalMessage('');
  };
  const openModal = () => {
    setIsModal(true);
  };

  const unsubscribe = () => {
    api.unsubscribeUser(userEmail)
      .then((response) => {
        const status = response.data.init_state;
        if (status.email_field) {
          setModalMessage(status.email_field);
        } else {
          setModalMessage(status.unsubscribe_status);
        }
      })
      .catch(() => {
        setModalMessage('При отписке произошла ошибка.');
      })
      .finally(() => {
        openModal();
      });
  };

  return (
    <>
      <div className={style.unsubscribe}>
        <div className={style.unsubscribe_content}>
          <h1 className={style.unsubscribe_content__title}>Вы больше не хотите получать от нас полезные материалы?</h1>
          <button onClick={unsubscribe} type="button" className={style.unsubscribe_content__button}>Отписаться от подписки</button>
        </div>
        <div className={style.unsubscribe_rounds}>
          <Shapes.Rounds />
        </div>
      </div>
      { isModal && <Modal endForm={endForm} modalMessage={modalMessage} modalSuccess /> }
    </>
  );
};

export default Unsubscribe;
