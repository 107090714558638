import React from 'react';
import Layout from '../Layout';
import ServiceBanner from '../components/Services/ServiceBanner';
import { TYPES_SERVICE } from '../const';
import ServiceHowSelect from '../components/Services/ServiceHowSelect';
import ServiceWhyWe from '../components/Services/ServiceWhyWe';
import PromoBlock from '../components/PromoBlock';
import ServiceScope from '../components/Services/ServiceScope';
import PortfolioMain from '../components/PortfolioMain';
import BlogMainPage from '../components/BlogMainPage';
import ServiceIntegrationPlans from '../components/Services/ServiceIntegrationPlans';

const CrmIntegrationPage = (props) => {
  const { object, global, posts } = props;
  return (
    <Layout {...props}>
      <ServiceBanner policy={global.policy_page} type={TYPES_SERVICE.crm_integration} title={object.page_title} page_info={object.page_info} />
      <ServiceScope
        capabilities={object.capabilities}
        title={object.capabilities_title}
        type="col"
      />
      <ServiceHowSelect policy={global.policy_page} how_select={object.how_select} type={TYPES_SERVICE.crm_integration} title={object.how_select_title} description={object.how_select_description} />
      <ServiceWhyWe advantages={object.advantages} title={object.advantages_title} />
      <ServiceIntegrationPlans integrations={object.integration_plans} title={object.integration_plans_title} />
      {object.banner ? <PromoBlock type={TYPES_SERVICE.SEO} data={object.banner} policy={global.policy_page} /> : null}
      <PortfolioMain data={object.portfolio} link={global.settings.caselist_url} />
      { posts.length !== 0
                && <BlogMainPage title="Популярные статьи" link={global.settings.blog_url} data={posts} />}
    </Layout>
  );
};

export default CrmIntegrationPage;
