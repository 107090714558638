import React from 'react';
import style from './index.module.scss';

const FooterCopyright = ({ policy }) => {
  return (
    <div className={style.footer_copyright}>
      <a href="https://garpix.com/" target="_blank" rel="noreferrer">2022 © Группа компаний GARPIX</a>
      <a href={policy || '/'} target="_blank" rel="noreferrer">Политика конфиденциальности</a>
    </div>
  );
};

export default FooterCopyright;
