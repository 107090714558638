import React from 'react';
import Tag from '../../CommonViews/Tag';
import style from './index.module.scss';

const CaseCard = ({ company_name, tags, logo }) => {
  return (
    <>
      <div className={style.portfolioPage_list__item_content}>
        <h2 className={style.portfolioPage_list__item__name}>{company_name}</h2>
        <ul className={style.portfolioPage_list__item_taglist}>
          {tags.map(({ id, name }) => {
            return <Tag key={id} name={name} projectVariant="tag__card" />;
          })}
        </ul>
      </div>
      {logo
        ? (
          <div className={style.portfolioPage_list__item__image}>
            <img src={logo} alt={company_name} />
          </div>
        )
        : null}
    </>
  );
};

export default CaseCard;
