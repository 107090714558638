import React from 'react';
import qs from 'query-string';
import Layout from '../Layout';
import BlogHeader from '../components/Blog/BlogHeader';
import SubscribeBlock from '../components/Blog/SubsribeBlock';
import BlogFetcher from './Fetchers/BlogFetcher';

const BlogPage = (props) => {
  const { object, popular_posts } = props;

  const filter_param = qs.parseUrl(window.location.href).query?.filter;

  return (
    <Layout {...props}>
      {!filter_param && <BlogHeader popularPosts={popular_posts} />}
      <BlogFetcher all_tags={object.all_tags} filter_param={filter_param} />
      <SubscribeBlock shapes="squares" />
    </Layout>
  );
};

export default BlogPage;
