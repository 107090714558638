import React from 'react';
import Layout from '../Layout/FooterLess';
import Message from '../components/CaptureFormSendPage/Message';

const CaptureFormPage = (props) => {
  const { object } = props;
  return (
    <Layout {...props}>
      <Message data={object} />
    </Layout>
  );
};

export default CaptureFormPage;
