import React, { useState } from 'react';
import style from './index.module.scss';
import ModalPromo from './ModalPromo';
import ModalSeoMagnet from '../Services/Modals/ModalSeoMagnet';
import { TYPES_SERVICE } from '../../const';

const PromoBlock = ({ data, policy, type }) => {
  const { banner_upper_text, banner_body_text, banner_icon } = data;
  const [isModal, setIsModal] = useState(false);

  const endForm = () => {
    setIsModal(false);
  };

  const openModal = () => {
    setIsModal(true);
  };

  return (
    <section className={style.promoBlock}>
      <div>
        <h3 className={style.promoBlock_name}>{banner_upper_text}</h3>
        <h2 className={style.promoBlock_title}>{banner_body_text}</h2>
        <button onClick={openModal} className={style.button} type="button">Получить инструкцию</button>
      </div>
      <img src={banner_icon} alt={banner_upper_text} />
      {isModal ? (type === TYPES_SERVICE.context_ad && <ModalPromo policy={policy} endForm={endForm} />) || (type === TYPES_SERVICE.SEO && <ModalSeoMagnet policy={policy} endForm={endForm} />) : null}
    </section>
  );
};

export default PromoBlock;
