import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import classNames from 'classnames/bind';
import ErrorInputField from '../../CommonViews/Form/ErrorInputField';

import style from '../../CommonViews/Modal/index.module.scss';
import api from '../../../api';
import useCloseModal from '../../../hooks/useCloseModal';
import calltouch from '../../../utils/metrika';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Обязательное поле')
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Некорректный email'),
});

const ModalPromo = ({ endForm, policy }) => {
  const [modalMessage, setModalMessage] = useState('Получить инструкцию');
  const [addModalMessage, setAddModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    api.contextAd_lead_magnet(data)
      .then((res) => {
        if (res.status === 201) {
          calltouch(data, 'Запрос инструкции по контексной рекламе');
          setModalSuccess(true);
          setModalMessage('Готово!');
          setAddModalMessage('Подробное руководство отправлено вам на почту. Если не найдете его во Входящих, не забудьте проверить папку Спам.');
          reset();
        }
      }).catch((e) => {
        setFormErrors(e.response.data);
      });
  };

  const modalRef = useCloseModal(endForm);

  return (
    <div className={style.modalBg} ref={modalRef}>
      <div className={
                classNames(style.modalContent, { [style.modalContent__withoutBg]: !modalSuccess })
            }
      >
        <button type="button" onClick={endForm} className={style.modalContent_close}>
          <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              x="9"
              y="7.74885"
              width="33"
              height="3"
              transform="rotate(45 9 7.74885)"
              fill="#C41FDF"
              stroke="#C41FDF"
            />
            <rect
              x="6.70711"
              y="31.0417"
              width="33"
              height="3"
              transform="rotate(-45 6.70711 31.0417)"
              fill="#C41FDF"
              stroke="#C41FDF"
            />
          </svg>
        </button>
        <h2 className={style.modalContent__formSuccess}>{modalMessage}</h2>
        {modalSuccess ? (
          <>
            <div className={style.modalContent__addText}>{addModalMessage}</div>
            <button type="button" className={style.modalContent__done} onClick={endForm}>Хорошо</button>
          </>
        ) : (
          <>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input
                type="email"
                className={style.modalContent__input}
                placeholder="E-mail"
                {...register('email')}
              />
              <ErrorInputField text={formErrors.email} />
              <p className={style.modalContent__error}>{errors.email?.message}</p>
              <button type="submit" className={style.modalContent_endForm}>Получить</button>
            </form>
            <p className={style.modalContent__agree}>
              Нажимая на кнопку «Получить», я соглашаюсь
              <br />
              с
              {' '}
              <a href={policy} target="_blank" rel="noreferrer">политикой конфиденциальности сайта.</a>
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ModalPromo;
