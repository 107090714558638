import React from 'react';
import Contact from '../components/Contact';
import ShortLayout from '../Layout/ShortLayout';

const ContactPage = (props) => {
  const { global, object } = props;
  return (
    <ShortLayout {...props}>
      <Contact {...global} address={object.address} title={object.contact_title} />
    </ShortLayout>
  );
};

export default ContactPage;
