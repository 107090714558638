import React from 'react';
import Helmet from 'react-helmet';
import '../../style/global/index.scss';
import Header from '../../components/LayoutViews/Header';

export default ({
  global,
  object,
  children,
}) => {
  const { seo_title } = object;
  const { settings, menus } = global;
  return (
    <>
      <Helmet>
        <title>{seo_title}</title>
      </Helmet>
      <div className="App">
        <Header settings={settings} menus={menus} />
        <div className="container">
          {children}
        </div>
      </div>
    </>
  );
};
