import React from 'react';
import ShortLayout from '../Layout/ShortLayout';
import Page404 from '../components/Page404';

const PageError404 = (props) => {
  const { global } = props;
  return (
    <ShortLayout {...props}>
      <Page404 link={global?.settings?.blog_url} />
    </ShortLayout>
  );
};

export default PageError404;
