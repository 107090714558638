import React from 'react';
import style from './index.module.scss';
import squid from '../../../images/animateElements/squid.svg';
import icon1 from '../../../images/animateElements/w-icon1.svg';
import icon2 from '../../../images/animateElements/w-icon2.svg';
import icon4 from '../../../images/animateElements/w-icon4.svg';
import icon5 from '../../../images/animateElements/w-icon5.svg';
import icon6 from '../../../images/animateElements/w-icon6.svg';
import icon7 from '../../../images/animateElements/w-icon7.svg';
import icon8 from '../../../images/animateElements/w-icon8.svg';
import icon9 from '../../../images/animateElements/w-icon9.svg';

const MainBanner = ({ slogan }) => {
  return (
    <section className={style.mainBanner} id="home">
      <div className={style.mainBanner__row}>
        <div className={style.mainBanner__left}>
          <h1 className={style.page_title}>{slogan}</h1>
        </div>
        <div className={style.mainBanner__right}>
          <div className={style.mainBanner__squids}>
            <div className={style.mainBanner__squidtext}>
              Креативный подход
              {' '}
              <br />
              с технической экспертизой
            </div>
            <img src={squid} alt="" className={style.mainBanner__squid1} />
            <img src={squid} alt="" className={style.mainBanner__squid2} />
            <img src={squid} alt="" className={style.mainBanner__squid3} />
            <img src={squid} alt="" className={style.mainBanner__squid4} />
            <img src={squid} alt="" className={style.mainBanner__squid5} />
          </div>
          <div className={style.mainBanner__icons}>
            <img src={icon1} alt="" className={style.mainBanner__icon1} />
            <img src={icon2} alt="" className={style.mainBanner__icon2} />
            <img src={icon4} alt="" className={style.mainBanner__icon4} />
            <img src={icon5} alt="" className={style.mainBanner__icon5} />
            <img src={icon6} alt="" className={style.mainBanner__icon6} />
            <img src={icon7} alt="" className={style.mainBanner__icon7} />
            <img src={icon8} alt="" className={style.mainBanner__icon8} />
            <img src={icon9} alt="" className={style.mainBanner__icon9} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default MainBanner;
