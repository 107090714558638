import React from 'react';
import Unsubscribe from '../components/Unsubscribe';
import ShortLayout from '../Layout/ShortLayout';

const UnsubscribePage = (props) => {
  return (
    <ShortLayout {...props}>
      <Unsubscribe unsub_link={props.unsub_link} />
    </ShortLayout>
  );
};

export default UnsubscribePage;
