import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import style from '../../CommonViews/Modal/index.module.scss';
import api from '../../../api';
import Modal from '../../CommonViews/Modal';
import ErrorInputField from '../../CommonViews/Form/ErrorInputField';
import calltouch from '../../../utils/metrika';

const schema = yup.object().shape({
  email: yup
    .string()
    .required('Обязательное поле')
    .matches(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/, 'Некорректный email'),
});

const ModalSeoMagnet = ({ endForm, policy }) => {
  const [modalMessage, setModalMessage] = useState('Получить инструкцию');
  const [addModalMessage, setAddModalMessage] = useState('');
  const [modalSuccess, setModalSuccess] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    api.seo_lead_magnet(data)
      .then((res) => {
        if (res.status === 201) {
          calltouch(data, 'Запрос инструкции СЕО');
          setModalSuccess(true);
          setModalMessage('Готово!');
          setAddModalMessage('Подробный чеклист отправлен вам на почту. Если не найдете его во Входящих, не забудьте проверить папку Спам.');
          reset();
        }
      }).catch((e) => {
        setFormErrors(e.response.data);
      });
  };

  return (
    <Modal addModalMessage={addModalMessage} modalMessage={modalMessage} modalSuccess={modalSuccess} endForm={endForm}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          type="email"
          className={style.modalContent__input}
          placeholder="E-mail"
          {...register('email')}
        />
        <ErrorInputField text={formErrors.email} />
        <p className={style.modalContent__error}>{errors.email?.message}</p>
        <button type="submit" className={style.modalContent_endForm}>Получить</button>
      </form>
      <p className={style.modalContent__agree}>
        Нажимая на кнопку «Получить», я соглашаюсь
        <br />
        с
        {' '}
        <a href={policy} target="_blank" rel="noreferrer">политикой конфиденциальности сайта.</a>
      </p>
    </Modal>
  );
};

export default ModalSeoMagnet;
