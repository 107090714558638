import React, { useState } from 'react';
import style from './index.module.scss';

const ScrollToTop = () => {
  const [hide, setHide] = useState(true);
  window.onscroll = () => {
    window.scrollY > 700 ? setHide(false) : setHide(true);
  };
  const scrollTop = () => {
    window.scrollTo(0, 0);
  };
  return (
    <button type="button" className={style.scrollToTop} onClick={scrollTop} style={{ display: hide ? 'none' : 'flex' }}>
      <svg width="13" height="30" viewBox="0 0 13 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.38446 2.61554C7.17213 2.4032 6.82787 2.4032 6.61554 2.61554L3.15536 6.07571C2.94303 6.28804 2.94303 6.6323 3.15536 6.84463C3.3677 7.05697 3.71196 7.05697 3.92429 6.84463L7 3.76893L10.0757 6.84463C10.288 7.05697 10.6323 7.05697 10.8446 6.84463C11.057 6.6323 11.057 6.28804 10.8446 6.07571L7.38446 2.61554ZM7.54371 27L7.54371 3L6.45629 3L6.45629 27L7.54371 27Z" fill="white" />
      </svg>
    </button>
  );
};

export default ScrollToTop;
