import { CALLTOUCH } from '../const';

export default function calltouch(formData, name) {
  const ctModId = CALLTOUCH.modId;
  const ctSiteId = CALLTOUCH.siteId;
  let result = Promise.resolve();
  if (ctModId && ctSiteId && typeof ct !== 'undefined') {
    const ctData = {
      fio: formData.name,
      phoneNumber: formData.phone,
      email: formData.email,
      comment: formData.comment,
      subject: name ?? 'Заявка с сайта',
      requestUrl: window.location.href,
    };
    const sessionId = window.ct('calltracking_params', ctModId)?.sessionId;
    if (sessionId) {
      ctData.sessionId = sessionId;
    }
    Object.keys(ctData).forEach((key) => {
      if (ctData[key] == null) {
        delete ctData[key];
      }
    });

    result = fetch(`https://api.calltouch.ru/calls-service/RestAPI/requests/${ctSiteId}/register/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: new URLSearchParams(ctData),
    });
  }

  return result;
}
