import React from 'react';
import cn from 'classnames';
import style from './index.module.scss';
import CaseCard from '../CaseCard';

const CasesBlock = ({ casesBlock = [], index }) => {
  const differentStyles = cn({
    [style.first]: index % 2 === 0,
    [style.second]: index % 2 !== 0,
  });
  return (
    <div className={style.portfolioPage_list}>
      {casesBlock && casesBlock.map(({
        company_name, tags, logo, url, id,
      }) => (
        <a key={id} href={url} className={cn(style.portfolioPage_list__item, differentStyles)}>
          <CaseCard
            company_name={company_name}
            tags={tags}
            logo={logo}
          />
        </a>
      ))}
    </div>
  );
};

export default CasesBlock;
