import React from 'react';
import { arrow } from '../../../images';
import style from './index.module.scss';

const HowWeWork = ({ data }) => {
  return (
    <section className={style.howWorking} id="howWeWork">
      <h2 className={style.section_title}>Как мы работаем</h2>
      <ul className={style.howWorking_list}>
        {data.map((item) => (
          <li key={item.id} className={style.howWorking_list__item}>
            <div className={style.stages_num}>{item.additional}</div>
            <img src={arrow} className={style.stages_arrow} alt="arrow" />
            <p className={style.stages_name}>{item.title}</p>
            <div className={style.stages_subtitle} dangerouslySetInnerHTML={{ __html: item.content }} />
          </li>
        ))}
      </ul>
    </section>
  );
};

export default HowWeWork;
